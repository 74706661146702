export const AppUrl: { [key: string]: string } = {
  local: 'http://localhost:8000',
  staging: 'https://stag-app.plataformaverbo.com.br',
  production: 'https://app.plataformaverbo.com.br',
}

export const ssoUrl: { [key: string]: any } = {
  local: 'http://localhost:8001/',
  staging: 'https://staging-fractalid.fractaltecnologia.com.br',
  production: 'https://fractalid.fractaltecnologia.com.br'
}

export const ACTIVITY_STATUS: {
  corrected: string
  in_correction: string
  not_corrected: string
  overdue_enabled: string
  visualized: string
  not_visualized: string
} = {
  corrected: 'corrected',
  in_correction: 'in_correction',
  not_corrected: 'not_corrected',
  overdue_enabled: 'overdue_enabled',
  visualized: 'visualized',
  not_visualized: 'not_visualized'
}

export const ESSAY_MARKERS_COLORS: {
  c1Rect: string
  c2Rect: string
  c3Rect: string
  c4Rect: string
  c5Rect: string
  c1Line: string
  c2Line: string
  c3Line: string
  c4Line: string
  c5Line: string
  video: string
  tese: string
} = {
  c1Rect: '#F6BB8C',
  c2Rect: '#8842FE',
  c3Rect: '#32ADCA',
  c4Rect: '#FFD057',
  c5Rect: '#C9FF57',
  c1Line: 'rgba(255, 234, 216, 0.5)',
  c2Line: 'rgba(230, 216, 255, 0.5)',
  c3Line: 'rgba(188, 231, 241, 0.5)',
  c4Line: 'rgba(249, 236, 201, 0.5)',
  c5Line: 'rgba(227, 244, 192, 0.5)',
  video: '#EF7B8E',
  tese: '#343a40'
}

export const ESSAY_CRITERIA_KIND: {
  c1: string
  c2: string
  c3: string
  c4: string
  c5: string
  tese: string
} = {
  c1: 'C1',
  c2: 'C2',
  c3: 'C3',
  c4: 'C4',
  c5: 'C5',
  tese: 'TESE'
}

export const ESSAY_KIND: {
  line: string
  line2: string
  rect: string
  comm: string
  audio: string
} = {
  line: 'LINE',
  line2: 'LINE2',
  rect: 'RECT',
  comm: 'COMM',
  audio: 'AUDIO'
}