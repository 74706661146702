import React from 'react'

// Components
import { Routes as Switch, Route, Navigate } from 'react-router-dom'
import { privateRoutes, notLoggedRoutes, Route as RouteType } from './pathUrls'
import NotFoundPage from '../pages/NotFoundPage'

// Redux
import { useDispatch } from 'react-redux'
import {
  SIGN_OUT_REQUEST
} from '../store/auth/actions'

// Utils
import reverse from 'lodash/reverse'
import { useAppSelector } from '../store/hooks'

const Routes = () => {

  const dispatch = useDispatch()
  const { isLogged } = useAppSelector(state => state.authVerbo)

  const filteredPrivateRoutes = React.useCallback((): RouteType[] => {
    const filtered = privateRoutes
    if (isLogged && (!filtered || filtered.length === 0)) {
      dispatch(SIGN_OUT_REQUEST())
    }
    return filtered
  }, [])

  const defaultPrivateRoute = filteredPrivateRoutes().find((route) => route.default)

  const setRoute = (route: any) => {
    const { component: Component, template: Template } = route
    const props = {
      ...route,
      element: route.template ? (
        <Template
          title={route.title}>
          <Component />
        </Template>
      )
        : <Component />,
      exact: true
    }
    return <Route key={route.path} {...props} />
  }

  const setPrivateRoute = (route: RouteType) => {
    return setRoute({
      ...route,
      template: route.template,
      component: route.component
    })
  }

  const setNavigate = (route: RouteType) => <Route
    key={route.path}
    element={
      <Navigate
        to={defaultPrivateRoute?.path ? defaultPrivateRoute.path : '/'}
      />
    }
  />

  const routesPrecedence = [
    filteredPrivateRoutes().map(setPrivateRoute),
    notLoggedRoutes.map(isLogged ? setNavigate : setRoute),
  ]

  const routes = isLogged ? routesPrecedence : reverse(routesPrecedence)
  return (
    <Switch>
      {routes}
      <Route path='*' element={<NotFoundPage />} />
    </Switch>
  )
}

export default Routes
