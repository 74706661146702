import { all, call, takeLatest, put, select } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as services from './services'
import * as actions from './actions'

export function* getNotificationsListSaga({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(services.getNotificationsList, payload);
    if (response.status === 200) {
      yield put(actions.getNotificationsListSuccess(response?.data))
    }
  } catch (error: any) {
    yield put(actions.getNotificationsListRejected(error))
  }
}
export function* postNotificationReceivedSaga({ payload }: any) {
  try {
    const { items } = yield select(state => state.notifications)

    const response: AxiosResponse = yield call(services.postNotificationReceived, payload)
    if (response.status === 200) {
      yield put(actions.postNotificationReceivedSuccess(payload))
      const data = items.filter((item: any) => item.id !== payload?.[0])
      yield put(actions.getNotificationsListSuccess(data))
    }
  } catch (error: any) {
    yield put(actions.postNotificationReceivedRejected(error))
  }
}

// Watchers
export function* watchGetNotificationsList() {
  yield takeLatest(actions.getNotificationsListRequest.type, getNotificationsListSaga)
}
export function* watchPostNotificationReceived() {
  yield takeLatest(actions.postNotificationReceivedRequest.type, postNotificationReceivedSaga)
}

export default function* notificationsSagas() {
  yield all([
    watchGetNotificationsList(),
    watchPostNotificationReceived()
  ])
}