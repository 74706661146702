import React from 'react'

// Assets
import ArrowRight from '../../assets/card/arrow-right.svg'

// Components
import { Box, Button, Divider, Typography } from '@mui/material'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/GuidedWritingCard.module.scss'
import { truncateText } from '../../utils/functions'

type GuidedWritingCardProps = {
  id: number | null
  title: string
  banca: string
  envio: string
  status: string
  score: number
}

const GuidedWritingCard: React.FC<GuidedWritingCardProps> = ({
  id,
  title,
  banca,
  envio,
  status,
  score
}) => {

  const theme = useTheme()

  const getTextColor = (status: string) => {
    let color
    switch (status) {
      case 'Em Correção':
        color = '#F6BB8C'
        return color
      case 'Prazo Expirado':
        color = theme.palette.error.main
        return color
      default:
        color = theme.palette.text.primary
        return color
    }
  }

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = theme.palette.secondary.main
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  return (
    <Box className={styles.container}>
      <div className={styles.flexColumn}>
        <span className={styles.label}>
          ID: <span className={styles.data}>{id}</span>
        </span>
        <Divider sx={{ paddingTop: '16px' }} />
      </div>
      <div className={styles.cardDataContainer}>
        <Box display='flex' justifyContent='space-between'>
          <h2 className={styles.cardTitle}>{truncateText(title, 35)}</h2>
          <Button>
            <img src={ArrowRight} />
          </Button>
        </Box>
        <div className={styles.flexColumn}>
          <div className={styles.doubleDataContainer}>
            <span className={styles.label}>
              Banca:
              <span className={styles.data}>
                {banca}
              </span>
            </span>
            <span className={styles.label}>
              Envio:
              <span className={styles.data}>
                {envio}
              </span>
            </span>
          </div>
          <div className={styles.doubleDataWrapper}>
            <span className={styles.label}>
              Status:
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%',
                  color: getTextColor(status)
                }}
                component='span'
              >
                {status}
              </Typography>
            </span>
            <span className={styles.label}>
              Nota:
              <Typography
                sx={{
                  background: getBackgroundColor(score),
                  borderRadius: '40px',
                  color: '#fff',
                  padding: '8px 16px',
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: 500,
                  lineHeight: '150%',
                }}
                component='span'
              >
                {score}
              </Typography>
            </span>
          </div>
        </div>
      </div>
    </Box>
  )
}

export default GuidedWritingCard