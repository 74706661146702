import React from 'react'
import { useDispatch } from 'react-redux'
import { Field } from 'react-final-form'

// Components
import { Box } from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'
import { RoundedButton } from '../button/RoundedButton'
import ImageUpload from '../imageUpload/ImageUpload'
import InformationIconWithTooltip from '../tooltip/InformationIconWithTooltip'
import NavigateButtonWithPathLabel from '../button/NavigateButtonWithPathLabel'

// Styles
import styles from './styles/addEssayMobile.module.scss'
import { useTheme } from '@mui/material/styles'

type IEssayCreateFormProps = {
  setCurrentTab: any
  values: any
}

const EssayCreateFormMobile: React.FC<IEssayCreateFormProps> = ({
  values,
  setCurrentTab
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  return (
    <>
      <NavigateButtonWithPathLabel onClick={setCurrentTab} text='Minhas redações / Adicionar / Novo tema / Redações' />
      <Box>
        <PageSubTitle
          sx={{
            color: 'var(--palette-text-quaternary)',
            mb: '40px',
          }}
        >
          Redações
          <InformationIconWithTooltip
            info='É fundamental conferir seus erros anteriores antes de produzir novas redações! Redações digitadas ou escritas a lapis não serão corrigidas. Certifique-se de que sua redação está escrita a caneta, e de que está completamente legível na foto enviada.'
          />
        </PageSubTitle>
      </Box>

      <Field name='essayImage' component='input'>
        {({ input }) => (
          <ImageUpload
            isEssay
            imageData={values?.essayImage}
            setImage={(file: any) => {
              input.onChange(file)
            }}
            type='file'
            {...input}
          />
        )}
      </Field>
      <div className={styles.buttons_container}>
        <RoundedButton
          type='submit'
        >
          Finalizar
        </RoundedButton>
      </div>
    </>
  )
}

export default EssayCreateFormMobile