import httpV2 from '../../utils/httpV2'
import { getNotificationsListPayload } from './actions'

// Endpoints
const notificationsEndpoint = '/notification/'

export const getNotificationsList = (params: getNotificationsListPayload) => {
  return httpV2.get(`${notificationsEndpoint}list/`, { params })
}

export const postNotificationReceived = (params: any) => {
  return httpV2.post(`${notificationsEndpoint}received/mark/`, {ids: params?.ids} )
}