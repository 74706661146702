import { once } from 'lodash'
import { Middleware, AnyAction, Dispatch } from '@reduxjs/toolkit'

import axiosInstance from '../utils/http'
import axiosInstanceV2 from '../utils/httpV2'
import { SIGN_OUT_REQUEST as logout } from './auth/actions'

const clearUserOnAuthErrors = once(next => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      const { response } = error
      const isUnauthorizedError = [401, 400].indexOf(response?.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')

      if (isUnauthorizedError && !isAuthEndpoint) next(logout())

      return Promise.reject(error)
    }
  )
})
const clearUserOnAuthErrorsV2 = once(next => {
  axiosInstanceV2.interceptors.response.use(
    response => response,
    error => {
      const { response } = error
      const isUnauthorizedError = [401, 400].indexOf(response?.status) > -1
      const isAuthEndpoint = error.config.url.includes('auth')
      if (isUnauthorizedError && !isAuthEndpoint) next(logout())

      return Promise.reject(error)
    }
  )
})

const setXToken = (token: string) => {
  axiosInstance.defaults.headers.common['X-TOKEN'] = token
  axiosInstanceV2.defaults.headers.common['X-TOKEN'] = token
}

export const onAuthErrors: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  clearUserOnAuthErrors(next)
  clearUserOnAuthErrorsV2(next)
  next(action)
}

export const addTokenToRequest: Middleware = () => (next: Dispatch<AnyAction>) => (action: AnyAction) => {
  const isStorageRehydrate = action.type === 'persist/REHYDRATE'
  const isUserSignedIn = action.type === 'auth:UPDATE_CREDENTIALS_REQUEST'
  if (isStorageRehydrate && action.payload) {
    setXToken(action.payload.auth?.credentials)
  }

  if (isUserSignedIn && action.payload) {
    setXToken(action.payload.token)    
  }

  next(action)
}
