/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-no-bind */
import { Box, useMediaQuery } from '@mui/material'
import React, { useState } from 'react'
import { Document, Page } from 'react-pdf'
import { useTheme } from '@mui/material/styles'

type IPDFRenderProps = {
  file: string
}

const PdfRender: React.FC<IPDFRenderProps> = ({ file }) => {
  const theme = useTheme()
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        'div' : {
          width: '100% !important',
          height: '100% !important',
          display: 'flex',
          justifyContent: 'center'
        },
        'canvas' : {
          width: '100% !important',
          height: '100% !important'
        }
      }}>
      <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
        <Page renderTextLayer={false} pageNumber={pageNumber} />
      </Document>
    </Box>
  )
}


export default PdfRender