import React, { useEffect } from 'react'

// Components
import ActivityConcludedEssayMobileContainer from '../containers/activities/activityConcludedEssay/ActivityConcludedEssayMobileContainer'
import ActivityConcludedEssayDesktopContainer from '../containers/activities/activityConcludedEssay/ActivityConcludedEssayDesktopContainer'

// Style
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/system'


function ActivityVisualizeEssayPage() {
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    document.title = "Minha atividade | Verbo"
  }, [])

  return (
    
    smDown ? <ActivityConcludedEssayMobileContainer/> : <ActivityConcludedEssayDesktopContainer/>
    
  )
}

export default ActivityVisualizeEssayPage