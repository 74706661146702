import React, { useState } from 'react'

// Components
import GuidedWritingCard from '../card/GuidedWritingCard'

// Styles
import styles from './styles/guidedWritingMobileList.module.scss'

// Utils
import { rows } from '../table/guidedWriting/GuidedWritingTable'
import { scrollToTop } from '../../utils/functions'
import { Pagination } from '@mui/material'

const GuidedWritingMobileList = () => {
  const [page, setPage] = useState(1)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }
  return (
    <div
      className={styles.container}
    >
      {
        rows.map((item) => {
          return (
            <GuidedWritingCard
              key={item.id}
              id={item.id}
              title={item.tema}
              banca={item.banca}
              envio={item.envio}
              score={item.nota}
              status={item.status}
            />
          )
        })
      }
      <Pagination
        count={20}
        size='small'
        color='primary'
        page={page}
        onChange={handleChangePage}
        sx={{
          mt: '32px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      />
    </div>
  )
}

export default GuidedWritingMobileList