import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import TrashIcon from '../../../assets/activities/trash-icon.svg'

// Components
import {
  Box,
} from '@mui/material'
import ImageUpload from '../ImageUpload'
import { RoundedButton } from '../../button/RoundedButton'
import { Field, Form } from 'react-final-form'
import EssaySubmittedConfirmationModal from '../../modal/EssaySubmittedConfirmationModal'

// Redux
import { useDispatch } from 'react-redux'
import { postStudentActivityRequest } from '../../../store/activity/actions'

// Style
import {
  useTheme
} from '@mui/material/styles'

// Utils
import { getActivityId } from '../../../utils/functions'
import { useAppSelector } from '../../../store/hooks'


const EssayUpload = () => {
  const theme = useTheme()
  const [openConfirmation, setOpenConfirmation] = React.useState(false)
  const [save, setSave] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isError } = useAppSelector(state => state.activity)

  const path = location.pathname

  const activityId = getActivityId(path)

  const handleCloseConfirmation = () => {
    setOpenConfirmation(false)
    navigate('/atividades')
  }

  const handleDeleteFile = (form: any) => {
    form.change('uploadedImage', null)
    setSave(false)
  }

  let submit: any
  const handleCustomSubmit = (event: any) => {
    setOpenConfirmation(true)
    setSave(true)
    submit(event)
  }

  const onSubmit = (values: any) => {
    dispatch(postStudentActivityRequest({ id: activityId, file: values?.uploadedImage }))
  }
  return (
    <Form
      onSubmit={onSubmit}
      render={
        ({ handleSubmit, values, form }) => {
          submit = handleSubmit
          return (
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  marginTop: '28px'
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '20px',
                    marginBottom: '12px',
                    justifyContent: 'space-between'
                  }}
                >
                  {
                    values?.uploadedImage && <Box display='flex' gap='8px'>
                      <RoundedButton
                        sx={{
                          background: theme.palette.error.main
                        }}
                        onClick={() => handleDeleteFile(form)}
                      >
                        <img src={TrashIcon} />
                        Deletar
                      </RoundedButton>
                      <RoundedButton
                        disabled={save || isError}
                        onClick={event => handleCustomSubmit(event)}
                        type='submit'
                      >
                        {
                        isError ? ('Enviar') : (save ? 'Enviado' : 'Enviar')
                        }
                      </RoundedButton>
                    </Box>
                  }
                </Box>
                <Field name='uploadedImage'>
                  {({ input }) => (
                    <ImageUpload
                      isEssay
                      imageData={values?.uploadedImage}
                      setImage={(file: any) => {
                        input.onChange(file)
                      }}
                      type='file'
                      maxWidth='100%'
                      {...input}
                    />
                  )}
                </Field>
                <Field
                  name='timeSpent'
                  render={({ input }) => {
                    return <input type='hidden' {...input} />;
                  }}
                />
                {!isError && <EssaySubmittedConfirmationModal
                  hasCloseButton={true}
                  open={openConfirmation}
                  handleClose={handleCloseConfirmation}
                  closeOnBackdropClick={true}
                />}
              </Box>
            </form>
          )
        }
      }
    />
  )
}

export default EssayUpload