/* eslint-disable camelcase */
import React, { useEffect, useRef } from 'react'

// Assets
import EssayIcon from '../../assets/activities/essay-icon.svg'
import ErrorActivity from '../../assets/activities/error-activity.png'
import QuestionMarkIcon from '../../assets/activities/question-mark-circled-icon.svg'
import LargeEssayShape from '../../assets/activities/larger-essay-shape-top-right-.svg'
import SmallEssayShape from '../../assets/activities/small-essay-shape-top-right-.svg'
import RedactorCommentIcon from '../../assets/activities/redactor-comment-icon.svg'
import RedactorAudioIcon from '../../assets/activities/redactor-audio-icon.svg'

// Components
import {
  Box,
  Tooltip,
  Typography,
  Button
} from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'
import EssayLabel from '../label/EssayLabel'
import RedactorCommentModal from '../modal/RedactorCommentModal'

// Redux
import { IMarker } from '../../store/activity/types'

// Style
import { useTheme } from '@mui/material/styles'

// Utils
import { ESSAY_KIND } from '../../utils/constants'
import { getMarkerColor } from '../../utils/functions'
import RedactorAudioModal from '../modal/RedactorAudioModal'

type IEssayCardProps = {
  essay: string
  markers?: IMarker[]
  timeSpent?: string
  isSecondSubmission?: boolean
  isError?: boolean
}

const EssayCard: React.FC<IEssayCardProps> = ({
  essay,
  markers = [],
  timeSpent,
  isSecondSubmission,
  isError
}) => {
  const theme = useTheme()
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const markerLineWidth = 5

  const [openRedactorCommentModal, setOpenRedactorCommentModal] = React.useState(false)
  const [openRedactorAudioModal, setOpenRedactorAudioModal] = React.useState(false)
  const [redactorComment, setRedactorComment] = React.useState('')
  const [redactorAudio, setRedactorAudio] = React.useState('')

  const handleOpenRedactorCommentModal = (comment: string) => {
    setOpenRedactorCommentModal(true)
    setRedactorComment(comment)
  }
  const handleOpenRedactorAudioModal = (audioUrl: string) => {
    setOpenRedactorAudioModal(true)
    setRedactorAudio(audioUrl)
  }

  const handleCloseRedactorCommentModal = () => {
    setOpenRedactorCommentModal(false)
  }
  const handleCloseRedactorAudioModal = () => {
    setOpenRedactorAudioModal(false)
  }

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas && essay && markers.length > 0) {
      const ctx: any = canvas.getContext('2d')
      if (ctx) {
        const image = new Image()
        image.src = essay
        image.onload = () => {
          canvas.width = image.width
          canvas.height = image.height
          ctx.clearRect(0, 0, canvas.width, canvas.height)
          ctx.drawImage(image, 0, 0, image.width, image.height)

          // Desenhar todos os marcadores da redação corrigida
          markers.forEach(({ kind, x0, y0, x1, y1, criteria_kind }) => {
            // Ignorar marcador do tipo "COMM" para desenho
            if (kind === ESSAY_KIND.comm) return

            // Converter coordenadas normalizadas em absolutas
            const absoluteX0 = x0 * image.width
            const absoluteY0 = y0 * image.height
            const absoluteX1 = x1 * image.width
            const absoluteY1 = y1 * image.height
            const width = absoluteX1 - absoluteX0
            const height = absoluteY1 - absoluteY0

            const markerColor = getMarkerColor(criteria_kind, kind)

            if (kind === ESSAY_KIND.rect) {
              // Desenha somente bordas quando for do tipo RECT
              ctx.strokeStyle = markerColor
              ctx.lineWidth = markerLineWidth
              ctx.strokeRect(absoluteX0, absoluteY0, width, height)
            } else if (kind === ESSAY_KIND.line || kind === ESSAY_KIND.line2) {
              // Desenha retângulos preenchidos quando o marcador for do tipo LINE ou LINE2
              ctx.fillStyle = markerColor
              ctx.fillRect(absoluteX0, absoluteY0, width, height)
            }
          })
        }
      }
    }
  }, [essay, markers])

  return (
    <Box
      sx={{
        background: '#F2F5FF',
        borderRadius: '24px',
        height: 'fit-content',
        position: 'relative',
        padding: '28px 16px 40px 16px',
        width: '100%',
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px',
          position: 'absolute',
          top: 0,
          right: 0,
          backgroundImage: timeSpent ? `url(${LargeEssayShape})` : `url(${SmallEssayShape})`,
          width: timeSpent ? '285px' : '160px',
          height: '68px'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            position: 'absolute',
            right: '8px',
            top: 0
          }}
        >
          <Typography
            sx={{
              color: '#fff',
              fontWeight: 500,
              fontSize: '14px',
              background: isSecondSubmission ? '#EF7B8E' : theme.palette.text.primary,
              padding: '6px 15px',
              borderRadius: '56px'
            }}
          >
            {isSecondSubmission ? '2º Envio' : '1º Envio'}
          </Typography>
          <Box ml='4px'>
            <Tooltip title='Ajuda'>
              <img src={QuestionMarkIcon} />
            </Tooltip>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: '8px'
        }}
      >
        <img src={EssayIcon} />
        <PageSubTitle>Redação</PageSubTitle>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          mt: '28px',
          position: 'relative',
          '& > img': {
            width: '100%'
          },
          '& > canvas': {
            width: isError ? '215px' : '100%'
          }
        }}
      >
        {
          isError ? (
            <img src={ErrorActivity} />
          ) : (
            <>
              {
                markers.length === 0 ? (
                  <img src={
                    isError ? ErrorActivity : essay
                  }
                  />
                ) : (
                  <>
                    <canvas ref={canvasRef} />
                    {
                      markers.map(({
                        kind,
                        x0,
                        y0,
                        text_comment,
                        audio_file
                      }) => {
                        if (kind === ESSAY_KIND.comm) {
                          return <Button
                            key={`${x0}-${y0}`}
                            sx={{
                              position: 'absolute',
                              top: `${(y0 * 100) + 2}%`,
                              left: `${x0 * 100}%`,
                              transform: 'translate(-50%, -50%)',
                              padding: 0,
                              justifyContent: 'flex-end',
                              '&:hover': {
                                background: 'none'
                              }
                            }}
                            onClick={() => handleOpenRedactorCommentModal(text_comment)}
                          >
                            <img src={RedactorCommentIcon} alt='Comentário' />
                          </Button>
                        } else if (kind === ESSAY_KIND.audio) {
                          return <Button
                            key={`${x0}-${y0}`}
                            sx={{
                              position: 'absolute',
                              top: `${(y0 * 100) + 2}%`,
                              left: `${x0 * 100}%`,
                              padding: 0,
                              justifyContent: 'flex-end',
                              '&:hover': {
                                background: 'none'
                              }
                            }}
                            onClick={() => handleOpenRedactorAudioModal(audio_file)}
                          >
                            <img src={RedactorAudioIcon} alt='Arquivo de audio' />
                          </Button>
                        }
                        return null
                      })
                    }
                  </>
                )
              }
            </>
          )
        }
        {
          isError && <Typography
            sx={{
              fontSize: '24px',
              fontWeight: 600,
              lineHeight: '120%',
              marginTop: '16px'
            }}
          >
            Erro ao carregar a imagem.
          </Typography>
        }
      </Box>
      {
        !isError && <EssayLabel />
      }
      <RedactorCommentModal
        closeOnBackdropClick={true}
        handleClose={handleCloseRedactorCommentModal}
        comment={redactorComment}
        hasCloseButton={true}
        open={openRedactorCommentModal}
      />
      <RedactorAudioModal
        closeOnBackdropClick={true}
        handleClose={handleCloseRedactorAudioModal}
        audioUrl={redactorAudio}
        hasCloseButton={true}
        open={openRedactorAudioModal}
      />
    </Box>
  )
}

export default EssayCard
