import React from 'react'
import { useNavigate } from 'react-router-dom'

// Assets
import RightArrow from '../../assets/card/arrow-right.svg'

// Components
import { Box, Button, Typography } from '@mui/material'

// Redux
import { IActivity } from '../../store/activity/types'

// Styles
import styles from './styles/activityCardMobile.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { format } from 'date-fns'
import { translateStatus } from '../../utils/functions'
import { useAppSelector } from '../../store/hooks'
import ActivityCardMobileLoading from '../loading/ActivityCardMobileLoading'
import GradeWithLabel from '../text/GradeWithLabel'
import { ACTIVITY_STATUS } from '../../utils/constants'


type IActivityCardMobileProps = {
  name: string
  banca: string
  rewritten: boolean
  endDate: string
  status: string
  score: number | null
  item: IActivity
}

const ActivityCardMobile: React.FC<IActivityCardMobileProps> = ({
  name,
  banca,
  rewritten,
  endDate,
  score,
  status,
  item
}) => {

  const theme = useTheme()
  const navigate = useNavigate()
  const formattedEndDate = format(endDate, 'dd/MM/yyyy')
  const { isFetching } = useAppSelector(state => state.activity)

  const handleNavigate = (data: IActivity) => {
    const status = data?.answer.status
    if (status === ACTIVITY_STATUS.visualized || status === ACTIVITY_STATUS.not_visualized || status === ACTIVITY_STATUS.not_corrected) {
      navigate(`/minhas-atividades/visualizar/pendente/${data?.id}`, { state: { data: data } })
    } else if (status === ACTIVITY_STATUS.corrected) {
      navigate(`/minhas-atividades/visualizar/concluida/${data?.id}`, { state: { data: data } })
    } else if (status === ACTIVITY_STATUS.in_correction) {
      navigate(`/minhas-atividades/visualizar/em-correcao/${data?.id}`, { state: { data: data } })
    } else if (status === ACTIVITY_STATUS.overdue_enabled) {
      navigate(`/minhas-atividades/visualizar/prazo-expirado/${data?.id}`, { state: { data: data } })
    }
  }

  const getTextColor = (status: string) => {
    let color
    switch (status) {
      case 'Em Correção':
        color = '#F6BB8C'
        return color
      case 'Prazo Expirado':
        color = theme.palette.error.main
        return color
      default:
        color = theme.palette.text.primary
        return color
    }
  }

  if (isFetching) return <ActivityCardMobileLoading />

  return (
    <Box
      sx={{
        background: rewritten ? '#E6D8FF' : '#F2F5FF'
      }}
      className={styles.container}
    >
      <div className={styles.title}>
        <h3>{name}</h3>
        <Button
          onClick={() => handleNavigate(item)}
        >
          <img src={RightArrow} />
        </Button>
      </div>
      <div className={styles.data_container}>
        <div className={styles.data_sub_container}>
          <div className={styles.data_wrapper}>
            <span>Banca:</span>
            <span>{banca}</span>
          </div>
          <div className={styles.data_wrapper}>
            <span>Status:</span>
            <Typography
              sx={{
                color: getTextColor(status)
              }}
              component='span'
            >{translateStatus(status)}</Typography>
          </div>
        </div>
        <div className={styles.data_sub_container}>
          <div className={styles.data_wrapper}>
            <span>Término:</span>
            <span>{formattedEndDate}</span>
          </div>
          <div className={styles.data_wrapper}>
            <GradeWithLabel
              gradeFontSize='14px'
              labelFontSize='14px'
              labelFontWeight={500}
              gradeFontWeight={500}
              grade={score}
            />
          </div>
        </div>
      </div>
    </Box>
  )
}

export default ActivityCardMobile