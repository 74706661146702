import React from 'react';

// Components
import TabContext from '@mui/lab/TabContext';
import { Box, Tab } from '@mui/material';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Form } from 'react-final-form';
import EssayThemesFormMobile from '../../components/form/EssayThemesFormMobile'
import EssayCreateFormMobile from '../../components/form/EssayCreateFormMobile'

// Style
import styles from './styles/essayForms.module.scss'

const AddNewThemeMobileContainer = () => {
  const [currentTab, setCurrentTab] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentTab(newValue);
  }

  const onSubmit = (values: any) => {
    // eslint-disable-next-line no-console
    console.log('onSubmit values', values)
  }

  return (
    <Box className={styles.container}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, values }) => {
          return (
            <form onSubmit={handleSubmit} className={styles.container}>
              <TabContext value={currentTab}>
                <Box>
                  <TabList
                    sx={{ display: 'none' }}
                    onChange={handleChange}
                  >
                    <Tab value='1' />
                    <Tab value='2' />
                  </TabList>
                </Box>
                <TabPanel value='1'>
                  <EssayThemesFormMobile
                    values={values}
                    setCurrentTab={setCurrentTab}
                  />
                </TabPanel>
                <TabPanel value='2'>
                  <EssayCreateFormMobile
                    values={values}
                    setCurrentTab={setCurrentTab}
                  />
                </TabPanel>
              </TabContext>
            </form >
          )
        }}
      />
    </Box>
  )
}

export default AddNewThemeMobileContainer