import React from 'react'

// Components
import { Field } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { Box, Typography, styled } from '@mui/material'
import { PageSubTitle } from '../title/PageSubTitle'
import SelectInput from '../input/SelectInput'
import { RoundedButton } from '../button/RoundedButton'

// Style
import { useTheme } from '@mui/material/styles'
import styles from './styles/addNewThemeMobile.module.scss'
import ImageUpload from '../imageUpload/ImageUpload'

type IEssayThemesFormProps = {
  setCurrentTab: any
  values: any
}

const ThemeTitleInput = styled('input')(({ theme }) => ({
  borderRadius: '12px',
  maxWidth: '743px',
  padding: '25px 15px',
  border: '1px solid #BEBEBE',
  outline: 'none',
  '::placeholder': {
    color: theme.palette.text.secondary,
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '120%'
  },
  ':focus': {
    border: 'none',
    outline: `2px solid ${theme.palette.primary.light}`
  }
}))

const EssayThemesFormMobile: React.FC<IEssayThemesFormProps> = ({
  values,
  setCurrentTab
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const theme = useTheme()

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' },
  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options,
  ]

  const isDisabled = !values?.title || !values?.selectSubject || !values.themeImage

  return (
    <Box className={styles.container}>
      <Box>
        <PageSubTitle
          sx={{
            color: 'var(--palette-text-quaternary)',
            mb: '40px',
          }}
        >
          Novo tema
        </PageSubTitle>
      </Box>
      <div className={styles.input_container}>
        <Field
          name='title'
          render={({ input }) => (
            <ThemeTitleInput {...input} placeholder='Insira um título' />
          )}
        />
        <p className={styles.characters_limit}>
          {values.title ? values.title.length : 0}/250
        </p>
      </div>
      <div className={styles.banca_select}>
        <Typography className={styles.banca}>Escolha a banca avaliadora:</Typography>
        <Field name='selectSubject' component='select'>
          {({ input }) => (
            <SelectInput
              width='fit-content'
              border='none'
              {...input}
              defaultValue='placeholder'
              options={bancaOptions}
            />
          )}
        </Field>
      </div>
      <Field name='themeImage' component='input'>
        {({ input }) => (
          <ImageUpload
            maxWidth='743px'
            imageData={values?.themeImage}
            setImage={(file: any) => {
              input.onChange(file)
            }}
            type='file'
            {...input}
          />
        )}
      </Field>
      <div className={styles.buttons_container}>
        <RoundedButton
          onClick={() => setCurrentTab('2')}
          disabled={isDisabled}
        >
          Continuar
        </RoundedButton>
      </div>
    </Box>
  )
}

export default EssayThemesFormMobile