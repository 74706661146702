import React, { Fragment, useEffect, useState } from 'react'

// Assets
import { ReactComponent as NotificationIcon } from '../../assets/home/notification-icon.svg'
import CloseCircledIcon from '../../assets/home/card/close-circled-red-icon.svg'

// Components
import NotificationItemCard from '../card/NotificationItemCard'
import {
  Divider,
  Menu,
  styled,
  Typography,
  useMediaQuery
} from '@mui/material'
import InfiniteScroll from "react-infinite-scroll-component"

// Redux
import { useDispatch } from 'react-redux'
import { INotificationsList } from '../../store/notification/types'
import { getNotificationsListRequest } from '../../store/notification/actions'
import { useAppSelector } from '../../store/hooks'

// Style
import styles from './notification.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { truncateText } from '../../utils/functions'
import { format } from 'date-fns'

type INotificationProps = {
  background?: string
}

const Button = styled('button')(() => ({}))

const Notification: React.FC<INotificationProps> = ({
  background
}) => {

  const theme = useTheme()
  const dispatch = useDispatch()
  const { items, pagination } = useAppSelector(state => state.notifications)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [currentNotificationPage, setCurrentNotificationPage] = useState<number>(1)
  const [notificationList, setNotificationList] = useState<any>()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    dispatch(getNotificationsListRequest({ received: false, per_page: 5 }))
  }, [])

  useEffect(() => {
    setNotificationList(items)
  }, [items])

  const fetchMoreNotifications = (page: number) => {
    dispatch(getNotificationsListRequest({ page, per_page: 5, received: false }))
    setCurrentNotificationPage(currentNotificationPage + 1)
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button
        id='notification-button'
        aria-controls={open ? 'notification-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className={styles.button}
        sx={{
          background: background
        }}
      >
        {
          items.length !== 0 &&
          <div className={styles.notification_circle} />
        }
        <NotificationIcon />
      </Button>
      <Menu
        id='notification-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification-button',
        }}
        sx={{
          '.MuiPaper-root': {
            width: '100%',
            maxWidth: smDown ? '350px' : '450px'
          },
          [theme.breakpoints.up(905)]: {
            '.MuiPaper-root': {
              background: 'transparent'
            },
            '.MuiMenu-list': {
              paddingBlock: 0
            }
          }
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className={styles.notification_list_container}>
          <InfiniteScroll
            dataLength={notificationList?.length}
            next={() => fetchMoreNotifications(currentNotificationPage + 1)}
            height={'320px'}
            hasMore={currentNotificationPage < pagination.pages_total}
            loader={
              <Typography
                sx={{
                  textAlign: 'center',
                  paddingBlock: '8px'
                }}
              >
                Carregando...
              </Typography>
            }
            endMessage={
              <Typography
                sx={{
                  textAlign: 'center',
                  paddingBlock: '8px',
                  fontSize: '12px'
                }}
              >
                {pagination?.count === 0 ? 'Não há notificações.' : 'Não há mais notificações.'}
              </Typography>
            }
          >
            {
              notificationList?.map((item: INotificationsList) => {
                return (
                  <Fragment key={item.id}>
                    <button
                      onClick={handleClose}
                      className={styles.close_icon}
                    >
                      <img src={CloseCircledIcon} />
                    </button>
                    <NotificationItemCard
                      text={truncateText(item?.title, 80)}
                      created={item.created && format(item.created, 'dd/MM/yyyy')}
                      item={item}
                    />
                    <Divider />
                  </Fragment>
                )
              })
            }
          </InfiniteScroll>
        </div>
      </Menu>
    </>
  )
}

export default Notification