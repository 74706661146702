import React, { useState } from 'react'

// Assets
import GuidedWritingWomanWithBooks from '../../assets/guidedWriting/guided-writing-image.png'
import GirlWithMagnifier from '../../assets/guidedWriting/girl-with-magnifier.png'
import PlusRoundedIcon from '../../assets/guidedWriting/plus-rounded-icon.svg'

// Components
import { Box, Pagination, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import EssayThemeCards from './EssayThemeCards'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import SelectInput from '../../components/input/SelectInput'
import InformationTopRight from '../../components/tooltip/InformationTopRight'
import { RoundedButtonColorLight } from '../../components/button/RoundedButtonColorLight'

// Style
import styles from './styles/guidedWritingMobile.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { scrollToTop } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'
import GuidedWritingMobileList from '../../components/list/GuidedWritingMobileList'

const GuidedWritingMobileContainer = () => {
  const theme = useTheme()
  const [cardsPage, setCardsPage] = useState(1)
  const [mainTheme, setMainTheme] = useState(true)
  const navigate = useNavigate()

  const [tabValue, setTabValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (mainTheme) {
      setMainTheme(false)
    } else {
      setMainTheme(true)
    }
    setTabValue(newValue)
  }

  const handleButtonNavigate = () => {
    if (!mainTheme) {
      navigate('/redacao-guiada/adicionar-tema')
    } else {
      setMainTheme(false)
      setTabValue('2')
    }
  }

  const handleChangeCardsPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCardsPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }

  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]
  return (
    <div className={styles.container}>
      <div
        className={styles.title_container}
      >
        <Box        
          sx={{
            width: '343px',
            position: 'absolute',
            'img': {
              position: 'relative'
            },
            
          }}
        >
          <img width='343px' src={mainTheme ? GuidedWritingWomanWithBooks : GirlWithMagnifier} />
          <InformationTopRight
            info={
              mainTheme ? 'Redação Guiada' : 'Revise seus erros antes de novas redações! Apenas redações escritas à caneta e legíveis serão corrigidas'
            }
          />
        </Box>
      </div>
      <RoundedButtonColorLight
        sx={{
          marginTop: '170px',
          justifyContent: 'center',
          width: '100%'
        }}
        onClick={handleButtonNavigate}>
        {mainTheme ?
          <>
            <img src={PlusRoundedIcon} />
            Adicionar
          </>
          : 'Novo Tema'
        }
      </RoundedButtonColorLight>

      <div
        className={styles.tabs_container}
      >
        <Box sx={{ width: '100%' }}>
          <Box width='100%'>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    '.Mui-selected': {
                      color: theme.palette.text.primary + '!important',
                      fontWeight: 600,
                      fontSize: '24px'
                    },
                    '.MuiTab-root': {
                      color: theme.palette.text.secondary,
                      fontWeight: 600,
                      fontSize: '24px'
                    },
                    '.MuiTabs-indicator': {
                      background: theme.palette.primary.light,
                    }
                  }}
                >
                  <Tab label='Redações' value='1' />
                  <Tab label='Temas' value='2' />
                </TabList>
              </Box>
              <TabPanel value='1'>
                <Box>
                  <GuidedWritingMobileList />
                </Box>
              </TabPanel>
              <TabPanel value='2'>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '65px',
                    marginBottom: '34px'
                  }}
                >
                  <PageSubTitle>Encontre um tema</PageSubTitle>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography>Banca:</Typography>
                    <SelectInput
                      width='fit-content'
                      name='selectSubject'
                      border='none'
                      onChange={() => null}
                      defaultValue='placeholder'
                      options={bancaOptions}
                    />
                  </Box>
                </Box>
                <EssayThemeCards />
                <Pagination
                  count={20}
                  size='small'
                  color='primary'
                  page={cardsPage}
                  onChange={handleChangeCardsPage}
                  sx={{
                    mt: '32px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>

      </div>

    </div>
  )
}

export default GuidedWritingMobileContainer