
import React from 'react'

// Assets
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TeacherAvatar from '../../../assets/activities/teacher-avatar.png'

// Components
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  styled,
  Typography
} from '@mui/material'

// Redux
import { ICorrection, ICriteriaGrade } from '../../../store/activity/types';

// Utils
import { getBackgroundColor, getGradePercentage } from '../../../utils/functions'

type IEssayCompetenceCollapseProps = {
  firstSubmission: ICorrection
  secondSubmission?: ICorrection
}

const AccordionTitle = styled(Typography)(() => ({
  fontSize: '18px',
  fontWeight: 600,
  lineHeight: '120%'
}))
const ScoreText = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '120%'
}))
const LabelText = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '120%'
}))
const PercentageChip = styled(Typography)(() => ({
  borderRadius: '40px',
  fontWeight: 500,
  lineHeight: '120%',
  padding: '8px 16px',
}))

const EssayCompetenceCollapse: React.FC<IEssayCompetenceCollapseProps> = ({
  firstSubmission,
  secondSubmission
}) => {

const hasSecondSubmission = secondSubmission?.id
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        '.MuiAccordion-root:first-of-type': {
          borderTopLeftRadius: '20px !important',
          borderTopRightRadius: '20px !important',
        },
        '.MuiAccordion-root:last-of-type': {
          borderBottomLeftRadius: '20px !important',
          borderBottomRightRadius: '20px !important',
        },
        '.MuiAccordion-root.Mui-expanded': {
          margin: 0
        },
        '.MuiAccordion-root::before': {
          display: 'none'
        }
      }}
    >
      {
        firstSubmission?.criteria_grades?.map((item: ICriteriaGrade, index: number) => {
          const competenceOrder = index + 1
          const secondSubmissionItem: ICriteriaGrade =
            secondSubmission?.criteria_grades[index] || {
              criteria_id: null,
              criteria_name: '',
              criteria_max: 0,
              grade: 0,
              percentage: 0,
              comment: ''
            }

          return (
            <Accordion
              key={item?.criteria_id}
              sx={{
                padding: '24px',
                background: getBackgroundColor(competenceOrder).competenceBackground,
                borderRadius: '20px',
                '.MuiAccordionDetails-root': {
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '16px'
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'
                sx={{
                  '.MuiAccordionSummary-content': {
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-between',
                    border: 'none',
                    margin: 0
                  }
                }}
              >
                <AccordionTitle>Competência {competenceOrder}</AccordionTitle>
                <Box sx={{ display: 'flex' }}>
                  <Box
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      gap: '8px',
                      marginRight: '35px'
                    }}
                  >
                    <LabelText>1º Envio:</LabelText>
                    <LabelText
                      sx={{
                        display: 'flex',
                        gap: '8px'
                      }}
                    >
                      <ScoreText>{item?.grade}</ScoreText> de {item?.criteria_max}
                    </LabelText>
                    <PercentageChip
                      bgcolor={getBackgroundColor(competenceOrder).percentageBackground}
                    >
                      {getGradePercentage(item?.grade, item?.criteria_max)}%
                    </PercentageChip>
                  </Box>
                  {
                    (secondSubmissionItem && hasSecondSubmission) && <Box
                      sx={{
                        alignItems: 'center',
                        display: 'flex',
                        gap: '8px',
                        marginRight: '35px'
                      }}
                    >
                      <LabelText>2º Envio:</LabelText>
                      <LabelText
                        sx={{
                          display: 'flex',
                          gap: '8px'
                        }}
                      >
                        <ScoreText>{secondSubmissionItem?.grade}</ScoreText> de {secondSubmissionItem?.criteria_max}
                      </LabelText>
                      <PercentageChip
                        bgcolor={getBackgroundColor(competenceOrder).percentageBackground}
                      >
                        {getGradePercentage(secondSubmissionItem?.grade, secondSubmissionItem?.criteria_max)}%
                      </PercentageChip>
                    </Box>
                  }
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <LabelText mb='17px'>1º Envio:</LabelText>
                  <Box display='flex'>
                    <Avatar
                      sx={{ width: 48, height: 48, marginRight: 1 }}
                      src={TeacherAvatar}
                    />
                    <Typography>
                      {item?.comment.length > 0 ? item?.comment : 'Não possui comentário.' }
                    </Typography>
                  </Box>
                </Box>
                {
                  (secondSubmissionItem && hasSecondSubmission) &&
                  <Box
                    sx={{
                      background: getBackgroundColor(competenceOrder).competenceSecondSentBackground,
                      borderRadius: '20px',
                      marginTop: '8px',
                      padding: '25px',
                    }}
                  >
                    <LabelText mb='17px'>2º Envio:</LabelText>
                    <Box display='flex'>
                      <Avatar
                        sx={{ width: 48, height: 48, marginRight: 1 }}
                        src={TeacherAvatar}
                      />
                      <Typography>
                        {secondSubmissionItem?.comment}
                      </Typography>
                    </Box>
                  </Box>
                }
              </AccordionDetails>
            </Accordion>
          )
        })
      }
    </Box>
  )
}

export default EssayCompetenceCollapse