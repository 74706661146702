import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  useLocation,
  useNavigate
} from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'

import { getUserDataRequest, updateCredentialsRequest } from '../store/auth/actions'
import { useAppSelector } from '../store/hooks'

const LoginPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { authVerbo: { isLogged } } = useAppSelector((state) => state)

  useEffect(() => {
    const queryString = 'token'
    const queryparams = new URLSearchParams(location.search)

    if (queryparams.has(queryString)) {
      dispatch(updateCredentialsRequest({ token: String(queryparams.get(queryString)) }))
    }
    !isLogged && dispatch(getUserDataRequest({ navigate }))
  }, [])

  return (
    <Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress color='secondary' />
      </Box>
    </Container>
  )
}

export default LoginPage
