import React, { useState } from "react"

// Assets
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'

// Components 
import { Box, Skeleton } from '@mui/material'
import Chart from "react-apexcharts"

// Redux
import { useAppSelector } from '../../store/hooks'
import { IActivity } from '../../store/activity/types'

// Styles
import './styles/home-chart.modules.scss'

// Utils
import { truncateText } from '../../utils/functions'
import { ACTIVITY_STATUS } from '../../utils/constants'

const HomePageChart = () => {
  const [page, setPage] = useState(0)
  const itemsPerPage = 5
  const { items, isFetching } = useAppSelector(state => state.activity)

  function getCorrectedGrades(activities: IActivity[]) {
    return activities
      ?.filter(activity => activity?.answer?.status === ACTIVITY_STATUS.corrected && typeof activity?.answer?.grade === 'number')
      ?.map(activity => {
        return { grade: activity?.answer?.grade, name: activity?.name }
      })
  }

  const grades: any = getCorrectedGrades(items?.activities);

  const seriesData = grades?.map((i: any) => i?.grade)
  const categories = grades?.map((i: any) => truncateText(i?.name, 22))

  const startIndex = page * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const paginatedSeriesData = seriesData && seriesData?.slice(startIndex, endIndex)
  const paginatedCategories = categories && categories?.slice(startIndex, endIndex)

  const getColors = (data: any) => {
    return data?.map((value: any) => {
      if (value <= 400) {
        return '#E6D8FF'
      } else if (value <= 799) {
        return '#8842FE'
      } else {
        return '#30037C'
      }
    })
  }

  const options = {
    chart: {
      id: "basic-bar"
    },
    xaxis: {
      categories: paginatedCategories
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 9,
        columnWidth: '20px',
      }
    },
    dataLabels: {
      enabled: false
    },
    grid: {
      show: false
    },
    colors: getColors(paginatedSeriesData)
  };

  const series = [
    {
      name: "Nota",
      data: paginatedSeriesData
    }
  ]

  const handleNext = () => {
    if (endIndex < seriesData?.length) {
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  const handlePrev = () => {
    if (startIndex > 0) {
      const prevPage = page - 1
      setPage(prevPage)
    }
  }

  if (isFetching) {
    return <Skeleton
      variant='rectangular'
      animation='wave'
      sx={{
        height: '300px',
        width: '100%',
        borderRadius: '20px',
        marginTop: '16px',
        marginBottom: '8px'
      }}
    />
  }

  return (
    <Box
      sx={{
        position: 'relative'
      }}
      className='app'>
      <Box sx={{
        paddingInline: '20px',
        '& > svg > g > g > text': {
          transform: 'none'
        }
      }} className='row'>
        <div className='mixed-chart'>
          <Chart
            options={options}
            series={series}
            type='bar'
            width='100%'
            height='300px'
          />
        </div>
      </Box>

      <button
        type='button'
        onClick={handlePrev}
        className='prev-chart-btn'
        disabled={startIndex === 0}
      >
        <span
          style={{
            position: 'absolute',
            borderWidth: 0
          }}
        >
        </span>
        <ArrowBackIosNewRoundedIcon sx={{ fontSize: '20px' }} />
      </button>
      <button
        onClick={handleNext}
        type='button'
        className='next-chart-btn'
        disabled={endIndex >= seriesData?.length}
      >
        <span style={{
          position: 'absolute'
        }}
        >
        </span>
        <ArrowForwardIosRoundedIcon sx={{ fontSize: '20px' }} />
      </button>
    </Box>
  );
};

export default HomePageChart;
