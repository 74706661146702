import { Box, Pagination, styled } from '@mui/material'
import React, { useState } from 'react'
import CustomTable, { Columns } from '../customTable/CustomTable';
import { useTheme } from '@mui/material/styles'
import { scrollToTop } from '../../../utils/functions';
type IGuidedWritingTableProps = {
  rows?: any
}

type Row = {
  id:number
  banca: string
  tema: string
  envio: string
  status: string
  nota: number
}

const TableTextCell = styled(Box)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '150%'
}))

export const rows: Row[] = [
  {
    id: 1,
    banca: 'ENEM',
    tema: 'O Papel da Tecnologia na Educação',
    envio: '05/ 05/ 2024',
    status: 'Concluída',
    nota: 1000
  },
  {
    id: 2,
    banca: 'ENEM',
    tema: 'A Importância da Preservação Ambie...',
    envio: '05/ 05/ 2024',
    status: 'Em Correção',
    nota: 978
  },
  {
    id: 3,
    banca: 'ENEM',
    tema: 'Os Impactos da Globalização na Cult...',
    envio: '05/ 05/ 2024',
    status: 'Pendente',
    nota: 952
  },
  {
    id: 4,
    banca: 'ENEM',
    tema: 'A Crise dos Refugiados: Solidariedade...',
    envio: '05/ 05/ 2024',
    status: 'Prazo Expirado',
    nota: 838
  },
  {
    id: 5,
    banca: 'ENEM',
    tema: 'Os Desafios da Saúde Mental na Soci...',
    envio: '05/ 05/ 2024',
    status: 'Concluída',
    nota: 535
  },
  {
    id: 6,
    banca: 'ENEM',
    tema: 'Educação Inclusiva: Rumo a uma Soc...',
    envio: '05/ 05/ 2024',
    status: 'Pendente',
    nota: 368
  }
]


const GuidedWritingTable: React.FC<IGuidedWritingTableProps> = () => {
  const theme = useTheme()

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = theme.palette.secondary.main
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  const getStatusColor = (status: string) => {
    let color = theme.palette.text.primary
    switch (status) {
      case 'Em Correção':
        color = '#F6BB8C'
        return color
      case 'Prazo Expirado':
        color = theme.palette.error.main
        return color
      default: color = theme.palette.text.primary
        return color
    }
  }

  const columns: Columns[] = [
    {
      key: 'id',
      name: 'ID',
      width: '5%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.id}
        </TableTextCell>
      }
    },
    {
      key: 'banca',
      name: 'Banca',
      width: '10%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.banca}
        </TableTextCell>
      }
    },
    {
      key: 'tema',
      name: 'Tema',
      width: '45%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.tema}
        </TableTextCell>
      }
    },
    {
      key: 'envio',
      name: 'Envio',
      width: '10%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell>
          {row.envio}
        </TableTextCell>
      }
    },
    {
      key: 'status',
      name: 'Status',
      width: '20%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell
          color={getStatusColor(row.status) + '!important'}
        >
          {row.status}
        </TableTextCell>
      }
    },
    {
      key: 'nota',
      name: 'Nota',
      width: '5%',
      align: 'left',
      render: (row: any) => {
        return <TableTextCell
          sx={{
            background: getBackgroundColor(row.nota),
            borderRadius: '40px',
            color: '#fff',
            padding: '8px 16px',
            textAlign: 'center'
          }}
        >
          {row.nota}
        </TableTextCell>
      }
    },

  ]

  const [page, setPage] = useState(1)

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }

  return (
    <Box>
      <CustomTable
        columns={columns}
        data={rows}
      />
      <Pagination
        count={20}
        size='small'
        color='primary'
        page={page}
        onChange={handleChangePage}
        sx={{
          mt: '32px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      />
    </Box>
  )
}

export default GuidedWritingTable