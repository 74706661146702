import { createReducer, current } from '@reduxjs/toolkit'

import * as actions from './actions'
import { StateType } from './types'

const initialState: StateType = {
  items: [],
  pagination: {
    count: 0,
    pages_total: 0
  },
  isFetching: false,
  isError: false,
  errorMessage: ''
}

const notificationsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getNotificationsListRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getNotificationsListSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        items: [
          ...state.items,
          ...action.payload.items
        ],
        pagination: {
          count: action.payload.count,
          pages_total: action.payload.pages_total
        }
      })
    })
    .addCase(actions.getNotificationsListRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.postNotificationReceivedRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.postNotificationReceivedSuccess, (state: StateType, action: { payload: any }): StateType => {
      const currentListState = current(state)
      return ({
        ...state,
        items: currentListState?.items?.filter(item => item?.id !== action?.payload?.ids[0]),
        isFetching: false,
        isError: false,
        errorMessage: '',
      })
    })
    .addCase(actions.postNotificationReceivedRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
})

export default notificationsReducer
