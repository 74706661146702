import React from 'react'
import {
  // useLocation,
  useNavigate
} from 'react-router-dom'
import { useDrawerContext } from '../../contexts/DrawerContext'

// Assets
import { ReactComponent as HomeIcon } from '../../assets/sidebar/home-icon.svg'
import { ReactComponent as ActivitiesIcon } from '../../assets/sidebar/activities-icon.svg'
import { ReactComponent as EsseyIcon } from '../../assets/sidebar/essey-icon.svg'
import { ReactComponent as ForumIcon } from '../../assets/sidebar/forum-icon.svg'
import { ReactComponent as MedalIcon } from '../../assets/sidebar/medal-icon.svg'
import { ReactComponent as SupportIcon } from '../../assets/sidebar/support-icon.svg'
import { ReactComponent as InstagramIcon } from '../../assets/sidebar/instagram-icon.svg'
import { ReactComponent as TwitterIcon } from '../../assets/sidebar/twitter-icon.svg'
import { ReactComponent as YoutubeIcon } from '../../assets/sidebar/youtube-icon.svg'
import LogoutIcon from '../../assets/sidebar/logout-icon.svg'

// Components
import { Box } from '@mui/system'
import {
  Divider,
  Drawer,
  Icon,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery
} from '@mui/material'
import MenuItemLink from '../sidebar/MenuItemLink'
import ActivityCounter from './ActivityCounter'

// Redux
import { useDispatch } from 'react-redux'
import { SIGN_OUT_REQUEST } from '../../store/auth/actions'
import { useAppSelector } from '../../store/hooks'

// Style
import { CSSObject, styled, useTheme } from '@mui/material/styles'

const openedMixin = (theme: Theme): CSSObject => ({
  width: theme.spacing(25),
  border: 'none',
  height: '100%',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  border: 'none',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  height: '100%',
  width: `calc(${theme.spacing(10)} + 1px)`,
  overflowY: 'hidden'
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: 'column',
  gap: '12px',
  justifyContent: 'center',
  padding: '8px 8px 80px',
  ...theme.mixins.toolbar,
}))

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexShrink: 0,
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      background: theme.palette.primary.main,
      height: '100% !important',
      position: 'relative',
      bottom: 0,
      left: 0,
      top: 0,
      right: 0
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
)

const Sidebar = () => {

  const {
    REACT_APP_PROJECT_LOGO_FULL,
    REACT_APP_PROJECT_LOGO_SMALL
  } = process.env

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDrawerOpen, toggleDrawerOpen } = useDrawerContext()

  const { statistics } = useAppSelector((state) => state.homeStatistics)


  return (
    <>
      {!smDown && (
        <DrawerStyled open={isDrawerOpen} variant={smDown ? 'temporary' : 'permanent'}>
          <Box
            width='100%'
            display='flex'
            flexDirection='column'
            height='fit-content'
          >
            <DrawerHeader>
              <Box
                sx={{
                  display: 'flex',
                  marginBottom: '30px',
                  position: 'relative',
                  width: '100%',
                }}
              >
                <IconButton
                  sx={{
                    position: 'absolute',
                    right: '-16px'
                  }}
                  onClick={toggleDrawerOpen}>
                  <Icon sx={{ color: '#fff' }}>{isDrawerOpen ? 'chevron_left' : 'chevron_right'}</Icon>
                </IconButton>
              </Box>
              <Box
                width='100%'
                display='flex'
                alignItems='center'
                justifyContent='center'
                gap='4px'
              >
                {
                  isDrawerOpen
                    ? (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                        width='200px'
                        src={REACT_APP_PROJECT_LOGO_FULL}
                        alt='Logo'
                      />
                    )
                    : (
                      <img
                        style={{ cursor: 'pointer' }}
                        onClick={() => navigate('/')}
                        width={'56px'}
                        src={REACT_APP_PROJECT_LOGO_SMALL}
                        alt='Logo'
                      />
                    )
                }
              </Box>
            </DrawerHeader>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} marginBottom='60px' flex={1}>
              <List sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '30px',
                marginLeft: '8px',
                marginBottom: '26px'
              }} component='nav'>
                <Tooltip title='Home'>
                  <ListItemButton
                    sx={{
                      gap: '9px',
                      justifyContent: 'center',
                      '& .MuiListItemIcon-root': {
                        minWidth: '0px',
                      }
                    }}
                    onClick={() => navigate('/')}>
                    <ListItemIcon>
                      <HomeIcon
                        width={isDrawerOpen ? '16px' : '32px'}
                        height={isDrawerOpen ? '16px' : '32px'}
                      />
                    </ListItemIcon>
                    {
                      isDrawerOpen && <ListItemText
                        sx={{
                          color: '#fff',
                          '& .MuiTypography-root': {
                            fontWeight: 500
                          }
                        }}
                        primary='Home' />
                    }
                  </ListItemButton>
                </Tooltip>
                <Tooltip title='Atividades'>
                  <ListItemButton
                    sx={{
                      gap: '9px',
                      justifyContent: 'center',
                      '& .MuiListItemIcon-root': {
                        minWidth: '0px',
                      }
                    }}
                    onClick={() => navigate('/atividades')}>
                    <ListItemIcon
                      sx={{
                        position: 'relative'
                      }}
                    >
                      <ActivitiesIcon
                        width={isDrawerOpen ? '16px' : '32px'}
                        height={isDrawerOpen ? '16px' : '32px'}
                      />

                      {!isDrawerOpen && <Box
                        sx={{
                          position: 'absolute',
                          bottom: '-10px',
                          right: '-16px'
                        }}
                      >
                        <ActivityCounter count={statistics.count_pending} />
                      </Box>}
                    </ListItemIcon>
                    {
                      isDrawerOpen && <ListItemText
                        sx={{
                          color: '#fff',
                          '& .MuiTypography-root': {
                            fontWeight: 500
                          }
                        }}
                        primary={
                          <Typography sx={{ display: 'flex', gap: '10px' }} component='span'>
                            Atividades <ActivityCounter count={statistics.count_pending} />
                          </Typography>
                        }
                      />
                    }
                  </ListItemButton>
                </Tooltip>
                <Tooltip title='Redação guiada'>
                  <ListItemButton
                    sx={{
                      gap: '9px',
                      justifyContent: 'center',
                      '& .MuiListItemIcon-root': {
                        minWidth: '0px',
                      }
                    }}
                    onClick={() => navigate('/redacao-guiada')}>
                    <ListItemIcon>
                      <EsseyIcon
                        width={isDrawerOpen ? '16px' : '32px'}
                        height={isDrawerOpen ? '16px' : '32px'}
                      />
                    </ListItemIcon>
                    {
                      isDrawerOpen && <ListItemText
                        sx={{
                          color: '#fff',
                          '& .MuiTypography-root': {
                            fontWeight: 500,
                            whiteSpace: 'nowrap'
                          }
                        }}
                        primary='Redação Guiada'
                      />
                    }
                  </ListItemButton>
                </Tooltip>
                <Tooltip title='Forum' >
                  <ListItemButton
                    sx={{
                      gap: '9px',
                      justifyContent: 'center',
                      '& .MuiListItemIcon-root': {
                        minWidth: '0px',
                      }
                    }}
                    onClick={() => navigate('/forum')}>
                    <ListItemIcon>
                      <ForumIcon
                        width={isDrawerOpen ? '16px' : '32px'}
                        height={isDrawerOpen ? '16px' : '32px'}
                      />
                    </ListItemIcon>
                    {
                      isDrawerOpen && <ListItemText
                        sx={{
                          color: '#fff',
                          '& .MuiTypography-root': {
                            fontWeight: 500
                          }
                        }}
                        primary='Forum'
                      />
                    }
                  </ListItemButton>
                </Tooltip>
                <Tooltip
                  title='Lista de Ranking'
                >
                  <ListItemButton
                    sx={{
                      gap: '9px',
                      justifyContent: 'center',
                      '& .MuiListItemIcon-root': {
                        minWidth: '0px',
                      }
                    }}
                    onClick={() => navigate('/painel-sucesso')}>
                    <ListItemIcon>
                      <MedalIcon
                        width={isDrawerOpen ? '16px' : '32px'}
                        height={isDrawerOpen ? '16px' : '32px'}
                      />
                    </ListItemIcon>
                    {
                      isDrawerOpen && <ListItemText
                        sx={{
                          color: '#fff',
                          '& .MuiTypography-root': {
                            fontWeight: 500
                          }
                        }}
                        primary='Sucesso'
                      />
                    }
                  </ListItemButton>
                </Tooltip>
              </List>
              <Box>
                <Divider sx={{ marginLeft: '16px', marginBottom: isDrawerOpen ? '18px' : '32px', background: '#fff' }} />
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '18px',
                    marginLeft: '16px',
                  }}
                >
                  {isDrawerOpen && <Box sx={{ display: 'flex', gap: '10px' }}>
                    <SupportIcon />
                    <Typography
                      sx={{
                        color: '#fff',
                        fontSize: '16px',
                        fontWeight: 500
                      }}
                    >
                      Suporte
                    </Typography>
                  </Box>}
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: `${!isDrawerOpen && 'center'}`
                    }}
                  >
                    {
                      isDrawerOpen && <Typography
                        sx={{
                          color: '#fff',
                          fontSize: '14px',
                          fontWeight: 500,
                          marginRight: '8px',
                          whiteSpace: 'nowrap'
                        }}
                      >
                        Nossas redes:
                      </Typography>
                    }
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: `${!isDrawerOpen && 'column'}`,
                        gap: '8px'
                      }}
                    >
                      <Typography
                        component='a'
                        href='https://instagram.com'
                        target='_blank'
                      >
                        <InstagramIcon
                          width={isDrawerOpen ? '16px' : '32px'}
                          height={isDrawerOpen ? '16px' : '32px'}
                        />
                      </Typography>
                      <Typography
                        component='a'
                        href='https://twitter.com'
                        target='_blank'
                      >
                        <TwitterIcon
                          width={isDrawerOpen ? '16px' : '32px'}
                          height={isDrawerOpen ? '16px' : '32px'}
                        />
                      </Typography>
                      <Typography
                        component='a'
                        href='https://youtube.com'
                        target='_blank'
                      >
                        <YoutubeIcon
                          width={isDrawerOpen ? '16px' : '32px'}
                          height={isDrawerOpen ? '16px' : '32px'}
                        />
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </DrawerStyled>
      )}
      {smDown && (
        <Drawer
          sx={{
            position: 'relative',
            '& .MuiPaper-root': {
              borderBottomRightRadius: '32px',
              borderTopRightRadius: '32px',
              height: '95%',
              overflowX: 'hidden',
              width: '95%',
              background: theme.palette.primary.main
            },
          }}
          open={isDrawerOpen}
          variant='temporary'
          onClose={toggleDrawerOpen}
        >
          <IconButton sx={{
            width: 'fit-content',
            alignSelf: 'end',
            margin: '10px'
          }}
            onClick={toggleDrawerOpen}
          >
            <Icon
              sx={{
                color: '#fff'
              }}
            >
              {isDrawerOpen ? 'chevron_left' : 'chevron_right'}
            </Icon>
          </IconButton>
          <Box height='100%' display='flex' flexDirection='column'>
            <Box
              sx={{
                alignItems: 'flex-start',
                display: 'flex',
                flexDirection: 'column',
              }}
              flex={1}
            >
              <List
                sx={{
                  display: 'flex',
                  flexDirection: 'column',

                }}
                component='nav'
              >
                <MenuItemLink
                  label='Home'
                  icon={
                    <HomeIcon />
                  }
                  to='/'
                  onClick={smDown && toggleDrawerOpen}
                />
                <MenuItemLink
                  label={<Typography alignItems='center' gap='10px' display='flex' component='span'>
                    Atividades <ActivityCounter count={statistics.count_pending} />
                  </Typography>}
                  icon={
                    <ActivitiesIcon />
                  }
                  to='/atividades'
                  onClick={smDown && toggleDrawerOpen}
                />
                <MenuItemLink
                  label='Notas'
                  icon={
                    <ActivitiesIcon />
                  }
                  to='/notas'
                  onClick={smDown && toggleDrawerOpen}
                />
                <MenuItemLink
                  label='Painel de Sucesso'
                  icon={
                    <MedalIcon />
                  }
                  to='/painel-sucesso'
                  onClick={smDown && toggleDrawerOpen}
                />
                <MenuItemLink
                  label='Redação Guiada'
                  icon={
                    <EsseyIcon
                      width={'16px'}
                      height={'16px'}
                    />
                  }
                  to='/redacao-guiada'
                  onClick={smDown && toggleDrawerOpen}
                />

                <ListItemButton onClick={() => dispatch(SIGN_OUT_REQUEST())}>
                  <ListItemIcon>
                    <img src={LogoutIcon} width={'20px'} />
                  </ListItemIcon>
                  <ListItemText
                    sx={{
                      color: '#fff',
                      '& .MuiTypography-root': {
                        fontWeight: 500
                      }
                    }}
                    primary='Sair' />
                </ListItemButton>
              </List>
            </Box>
          </Box>
        </Drawer >
      )}
    </>
  )
}

export default Sidebar