/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react'

// Assets
import CloudIcon from '../../assets/addNewTheme/cloud-icon.svg'
import CloseIcon from '../../assets/addNewTheme/close-icon.svg'
import ActivityIcon from '../../assets/activities/activity-icon.svg'

// Components
import {
  Box,
  Typography,
  IconButton,
  styled,
  Button,
  Tooltip,
  useMediaQuery
} from '@mui/material'
import PDFRender from '../dataRender/PdfRender'

// Style
import { useTheme } from '@mui/material/styles'
import styles from './imageUpload.module.scss'
import 'react-pdf/dist/Page/AnnotationLayer.css'

// Utils
import { bytesToMB } from '../../utils/functions'
import { toast } from 'react-toastify';
import { pdfjs } from 'react-pdf'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString()

type IImageUpload = {
  setImage: any
  maxWidth?: string
  imageData?: File
  isEssay?: boolean
}

export const ContainerUpload = styled(Box)(() => ({
  alignItems: 'center',
  background: '#F2F5FF',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '16px',
  maxWidth: '743px',
  width: '100%',
  'img': {
    borderRadius: '16px'
  }
}))

export const ContainerWithImage = styled(Box)(() => ({
  alignItems: 'flex-start',
  background: '#F2F5FF',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  paddingInline: '16px',
  width: '100%',
  'img': {
    borderRadius: '16px'
  }
}))

const ImageUpload: React.FC<IImageUpload> = ({
  isEssay,
  setImage,
  maxWidth,
  imageData
}) => {
  const theme = useTheme()
  const [fileImage, setFileImage] = useState(false)
  const [pdfFile, setPdfFile] = useState('')
  const smDown = useMediaQuery(theme.breakpoints.down('sm'))

  useEffect(() => {
    if (imageData && imageData.type === "application/pdf") {
      const fileURL = URL.createObjectURL(imageData)
      setPdfFile(fileURL)
    }
  }, [imageData])

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      if (file && file?.type !== 'image/png' && file?.type !== 'image/jpeg' && file?.type !== 'application/pdf') {
        toast.error("Formato de arquivo não permitido.")
      } else if (file.size > 10485760) {
        toast.error("Arquivo é maior que 10MB")
      } else {
        setImage(file)
        setFileImage(true)
      }
    }
  }

  const handleRemoveImage = () => {
    setImage(null)
    setFileImage(false)
    setPdfFile('')
  }

  return (
    <Box width={'100%'} display='flex' flexDirection='column' >
      {!imageData ? (
        <ContainerUpload
          sx={{
            paddingBlock: '67px',
            maxWidth: maxWidth
          }}
        >
          <input
            accept='image/png, image/jpeg, application/pdf'
            style={{ display: 'none' }}
            id='upload-button'
            type='file'
            onChange={handleImageUpload}
          />
          <label htmlFor='upload-button'>
            <IconButton component='span'>
              <Box sx={{
                alignItems: 'center',
                display: 'flex',
                background: theme.palette.primary.light,
                padding: '15px 12px',
                borderRadius: '50%'
              }}>
                <img src={CloudIcon} />
              </Box>
            </IconButton>
          </label>
          <Typography mt={2} fontSize='18px'>
            Escolha o arquivo para upload
          </Typography>
          <Typography mt={1} fontSize='14px' color='textSecondary'>
            PNG, JPG e PDF / Tamanho máx. 10MB
          </Typography>
        </ContainerUpload>
      ) : (
        <>
          {
            isEssay ? (
              <ContainerWithImage
                sx={{
                  paddingBlock: !fileImage ? '67px' : '16px',
                  position: 'relative',
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '15px',
                    width: '100%'
                  }}
                >
                  {
                    imageData.type !== 'application/pdf' &&
                    <img
                      src={URL.createObjectURL(imageData)}
                      alt={imageData?.name}
                      style={{ width: '100%' }}
                    />
                  }
                  {
                    (pdfFile && imageData.type === 'application/pdf') &&
                    (

                      smDown ? (
                        <div>
                          <PDFRender file={pdfFile} />
                          <Typography sx={{ fontSize: '12px', textAlign: 'center' }}>{imageData?.name}</Typography>
                        </div>
                      ) : (
                        <object
                          data={pdfFile}
                          type='application/pdf'
                          width='100%'
                          style={{ minHeight: smDown ? '100%' : '800px' }}
                        >
                          <span>
                            <img style={{ borderRadius: 0 }} src={ActivityIcon} />
                            {imageData.name}
                          </span>
                        </object>
                      )
                    )
                  }

                </Box>
              </ContainerWithImage>
            ) : (
              <ContainerWithImage
                sx={{
                  paddingBlock: !fileImage ? '67px' : '16px',
                  position: 'relative',
                  maxWidth: maxWidth
                }}
              >
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '15px'
                  }}
                >
                  <img src={URL.createObjectURL(imageData)} alt='Uploaded' style={{ maxWidth: '183px' }} />
                  <Box>
                    <Typography className={styles.file_name}>
                      {imageData?.name}
                    </Typography>
                    <Typography className={styles.file_size}>
                      {bytesToMB(imageData?.size)} MB
                    </Typography>
                  </Box>
                </Box>
                <Tooltip title='Remover'>
                  <Button
                    sx={{
                      margin: 0,
                      padding: 0,
                      position: 'absolute',
                      top: '16px',
                      right: '16px',
                      minWidth: '18px'
                    }}
                    onClick={handleRemoveImage}
                  >
                    <img src={CloseIcon} />
                  </Button>
                </Tooltip>
              </ContainerWithImage>
            )
          }
        </>
      )}
    </Box>
  )
}

export default ImageUpload