import React from 'react'

// Components
import { DataTitleLightGray } from './DataTitleLightGray'
import { PageItemTextBlack } from '../text/PageItemTextBlack'
import RoundedDateWithIcon from '../date/RoundedDateWithIcon'
import { Box } from '@mui/material'

// Style
import styles from './style/activityTitleWithData.module.scss'

type IActivityTitleWithDataProps = {
  theme: string
  classroom: string
  essayStartDate: string
  essayEndDate: string
  essayFirstSubmissionDate?: string
  essaySecondSubmissionDate?: string
  maxWidth?: string
}

const ActivityTitleWithData: React.FC<IActivityTitleWithDataProps> = ({
  theme,
  classroom,
  essayStartDate,
  essayEndDate,
  essayFirstSubmissionDate,
  essaySecondSubmissionDate,
  maxWidth
}) => {

  const essaySubject = theme?.length === 0 ? '--' : theme

  return (
    <Box
      className={styles.activity_data_title_container}
      maxWidth={maxWidth ?? '100%'}
    >
      <div className={styles.item}>
        <DataTitleLightGray>Tema</DataTitleLightGray>
        <PageItemTextBlack>{essaySubject}</PageItemTextBlack>
      </div>
      <div className={styles.activity_data_titles}>
        <Box mr='75px' className={styles.item}>
          <DataTitleLightGray>Turma</DataTitleLightGray>
          <PageItemTextBlack>{classroom ?? 'Não possui'}</PageItemTextBlack>
        </Box>
        <Box mr='72px' className={styles.item}>
          <DataTitleLightGray>Início</DataTitleLightGray>
          <RoundedDateWithIcon date={essayStartDate ?? '--/--/--'} />
        </Box>
        <Box mr='72px' className={styles.item}>
          <DataTitleLightGray>Término</DataTitleLightGray>
          <RoundedDateWithIcon isEndDate date={essayEndDate ?? '--/--/--'} />
        </Box>
        {
          essayFirstSubmissionDate && <Box mr='68px' className={styles.item}>
            <DataTitleLightGray>1ª Submissão</DataTitleLightGray>
            <PageItemTextBlack>{essayFirstSubmissionDate ?? '--/--/--'}</PageItemTextBlack>
          </Box>
        }
        {
          essaySecondSubmissionDate && <Box className={styles.item}>
            <DataTitleLightGray>2ª Submissão</DataTitleLightGray>
            <PageItemTextBlack>{essaySecondSubmissionDate ?? '--/--/--'}</PageItemTextBlack>
          </Box>
        }
      </div>
    </Box>
  )
}

export default ActivityTitleWithData