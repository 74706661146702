import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

// Assets
import DownloadIcon from '../../../assets/activities/download-icon.svg'
import ReportIcon from '../../../assets/activities/report-icon.svg'

// Component
import CustomSearchInput from '../../../components/input/CustomSearchInput'
import Notification from '../../../components/notification/Notification'
import { Box, LinearProgress, useMediaQuery } from '@mui/material'
import LogoutButton from '../../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../../components/dropDown/PersonalDataDropDownMenu'
import { PageSubTitle } from '../../../components/title/PageSubTitle'
import { RoundedButton } from '../../../components/button/RoundedButton'
import EssayCard from '../../../components/card/EssayCard'
import EssayCompetenceCollapse from '../../../components/collapse/activity/EssayCompetenceCollapse'
import NavigationTitleWithBackButton from '../../../components/navigation/NavigationTitleWithBackButton'
import ActivityTitleWithData from '../../../components/title/ActivityTitleWithData'
import ChatContainer from '../../../components/chat/ChatContainer'

// Redux
import { getStudentActivityByIdRequest, getStudentActivityReWriteByIdRequest } from '../../../store/activity/actions'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../store/hooks'

// Styles
import styles from './style/desktop.module.scss'
import { useTheme, styled } from '@mui/material/styles'

// Utils
import { format } from 'date-fns'
import { getActivityId, handleDownloadActivity } from '../../../utils/functions'
import { ACTIVITY_STATUS } from '../../../utils/constants'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ScoreChip = styled('span')(({ theme }) => ({
  borderRadius: '40px',
  color: '#fff',
  fontWeight: 600,
  fontSize: '24px',
  padding: '8px 16px',
}))

const ActivityConcludedEssayDesktopContainer = () => {

  const theme = useTheme()
  const location = useLocation()
  const state = location.state
  const dispatch = useDispatch()
  const path = location?.pathname
  const activityId: number | string = getActivityId(path) ?? ''
  const lgDown = useMediaQuery(theme.breakpoints.down('lg'))

  const [searchName, setSearchName] = useState<string>('')

  const { item, rewrite, isFetching, isError } = useAppSelector(state => state.activity)

  const firstSubmissionId = state?.data?.rewrite_of_id
  const secondSubmissionId = activityId || state?.data?.id
  const hasNoRewrite =
    (state?.data?.has_rewrite_id === null || item?.has_rewrite_id === null)
    && state?.data?.rewrite_of_id === null
  const isRewrite = rewrite?.id
  const isCorrected = item?.answer?.status === ACTIVITY_STATUS.corrected

  const IS_REWRITE = isRewrite !== null

  useEffect(() => {
    if (hasNoRewrite) {
      dispatch(getStudentActivityByIdRequest({ id: activityId }))
    } else {
      dispatch(getStudentActivityByIdRequest({ id: firstSubmissionId }))
      dispatch(getStudentActivityReWriteByIdRequest({ id: secondSubmissionId }))
    }
  }, [])

  const essayImageFirstSubmission = item?.answer?.file
  const essayImageSecondSubmission = rewrite?.answer?.file
  const essaySecondSubmissionDate = rewrite?.start_datetime
  const grade = Number(item.answer.grade)

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const getBackgroundColor = (score: number) => {
    let background
    if (score >= 900) {
      background = '#7EEB89'
    } else if (score >= 700 && score < 900) {
      background = theme.palette.text.primary
    } else if (score >= 400 && score < 700) {
      background = '#F6BB8C'
    } else if (score < 400) {
      background = theme.palette.error.main
    }
    return background
  }

  return (
    <div
      className={styles.container}
    >
      {isCorrected && <ChatContainer isRewrite={IS_REWRITE} />}
      <Box
        sx={{
          position: 'absolute',
          right: 0,
          bottom: '140vh',
          zIndex: 999
        }}
      >
      </Box>
      <div className={styles.top_header}>
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
          maxWidth='513px'
        />
        <div className={styles.notification_container}>
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/atividades'
        text='Atividades / Minhas atividades'
      />
      {
        isFetching ? (<LinearProgress />) :
          <>
            <div className={styles.activity_title_container}>
              <h1 className={styles.title}>
                {isRewrite ? rewrite.name : item.name}
              </h1>
              <div className={styles.button_container}>
                <div className={styles.score_container}>
                  {
                    item.answer.grade && (
                      <>
                        <span className={styles.label}>Nota:</span>
                        <ScoreChip sx={{ background: getBackgroundColor(grade) }}>{item.answer.grade}</ScoreChip>
                      </>
                    )
                  }
                </div>
                <RoundedButton disabled={!item.file_pdf} onClick={() => handleDownloadActivity(item?.file_pdf)}>
                  <img src={DownloadIcon} />
                  Baixar atividade
                </RoundedButton>
              </div>
            </div>
            <ActivityTitleWithData
              theme={item.subject}
              classroom={item?.answer?.group_name}
              essayStartDate={item.start_datetime && format(item.start_datetime, 'dd/MM/yyyy')}
              essayEndDate={item.start_datetime && format(item.end_datetime, 'dd/MM/yyyy')}
              essayFirstSubmissionDate={state?.data?.essay?.firstSentDate}
              essaySecondSubmissionDate={essaySecondSubmissionDate && format(essaySecondSubmissionDate, 'dd/MM/yyyy')}
            />
            <Box
              sx={{
                display: 'flex',
                flexDirection:
                  isRewrite ? 'column' :
                    (lgDown ? 'column' : 'row'),
                gap: '24px'
              }}
            >
              <Box className={styles.essay_container}>
                <EssayCard
                  isError={isError}
                  essay={essayImageFirstSubmission}
                  markers={item.answer.correction.markers}
                />
                {
                  essayImageSecondSubmission
                  && <EssayCard
                    isError={isError}
                    essay={essayImageSecondSubmission}
                    isSecondSubmission
                    markers={rewrite?.answer?.correction?.markers}
                  />
                }
              </Box>
              {
                !isError && <Box className={styles.report_container}>
                  <PageSubTitle>
                    <img src={ReportIcon} />
                    Relatório de correção
                  </PageSubTitle>
                  <h3 className={styles.reports_accordion_title}>Notas por critério</h3>
                  <EssayCompetenceCollapse
                    firstSubmission={item?.answer?.correction}
                    secondSubmission={rewrite?.answer?.correction}
                  />
                </Box>
              }
            </Box>
          </>
      }
    </div >
  )
}

export default ActivityConcludedEssayDesktopContainer