import React from 'react'
import DialogModal from './DialogModal'
import { Box, Divider, Typography } from '@mui/material'

type IRedactorCommentModalProps = {
  hasCloseButton: boolean
  open: boolean
  handleClose: () => void
  closeOnBackdropClick: boolean
  comment: string
}

const RedactorCommentModal: React.FC<IRedactorCommentModalProps> = ({
  hasCloseButton,
  open,
  handleClose,
  closeOnBackdropClick,
  comment
}) => {
  return (
    <DialogModal
      hasCloseButton={hasCloseButton}
      open={open}
      handleClose={handleClose}
      closeOnBackdropClick={closeOnBackdropClick}
    >
      <Box display='flex' flexDirection='column' paddingInline='16px'>
        <Typography component='h4' sx={{ textAlign: 'center', fontWeight: 500, marginBottom: '24px', fontSize: '18px' }}>Comentário:</Typography>
        <Divider/>
        <Typography
          marginTop='16px'
          component='p'
          fontWeight={400}
          fontSize='14px'
        >
          {comment}
        </Typography>
      </Box>
    </DialogModal>
  )
}

export default RedactorCommentModal