import { createAction } from '@reduxjs/toolkit'
import { withPayloadType } from '../../utils/functions'

export interface getNotificationsListPayload {
  per_page?: number | null
  page?: number | null
  received?: boolean 
}
export interface postNotificationReceivedPayload {
  ids: Array<any>
}

export const getNotificationsListRequest = createAction('notification:GET_NOTIFICATIONS_LIST_REQUEST', withPayloadType<getNotificationsListPayload>())
export const getNotificationsListSuccess = createAction('notification:GET_NOTIFICATIONS_LIST_SUCCESS', withPayloadType<any>())
export const getNotificationsListRejected = createAction('notification:GET_NOTIFICATIONS_LIST_REJECTED', withPayloadType<any>())

export const postNotificationReceivedRequest = createAction('notification:POST_NOTIFICATION_RECEIVED_REQUEST', withPayloadType<postNotificationReceivedPayload>())
export const postNotificationReceivedSuccess = createAction('notification:POST_NOTIFICATION_RECEIVED_SUCCESS', withPayloadType<any>())
export const postNotificationReceivedRejected = createAction('notification:POST_NOTIFICATION_RECEIVED_REJECTED', withPayloadType<any>())