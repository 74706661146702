import React from 'react'

// Assets
import ReadReceivedIcon from '../../assets/chat/read-recieved-icon.svg'
import SendChatIcon from '../../assets/chat/send-icon.svg'

// Components
import {
  Avatar,
  Box,
  Typography
} from '@mui/material'
import { stringAvatar } from '../../utils/functions'

// Styles
import { useTheme } from '@mui/material/styles'
import styles from './styles/chatBallon.module.scss'

// Utils
import { format, parseISO } from 'date-fns'
import { ptBR } from 'date-fns/locale'


type IChatBallonProps = {
  date: string
  isSending?: boolean
  read?: boolean
  message?: string
}

const ChatBallon: React.FC<IChatBallonProps> = ({
  date,
  isSending,
  message,
  read = false
}) => {
  const theme = useTheme()
  const dateFormatted = format(parseISO(date), "dd 'de' MMM 'de' yyyy", { locale: ptBR });

  return (
    <Box
      className={styles.container}
      sx={{
        display: 'flex',
        justifyContent: isSending ? 'flex-end' : 'flex-start',
      }}
    >
      {
        !isSending && <Avatar
          alt='Corretor'
          variant='circular'
          {...stringAvatar('Corretor', 44, 44)}
        />
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '8px',
        }}
      >
        <Box
          className={styles.chat_ballon}
          sx={{
            background: isSending ? theme.palette.primary.light : '#fff',
            borderTopRightRadius: '20px',
            borderTopLeftRadius: isSending ? '20px' : '0px',
            borderBottomRightRadius: isSending ? '0px' : '20px',
            borderBottomLeftRadius: '20px'
          }}
        >
          <Typography
            sx={{
              paddding: '16px 16px 24px 16px',
              color: isSending ? '#fff' : 'inherit',
            }}
          >
            {message}
          </Typography>
        </Box>
        <p
          className={styles.text_date}>
          {dateFormatted}
          <img src={read ? ReadReceivedIcon : SendChatIcon} />
        </p>
      </Box>

    </Box>
  )
}

export default ChatBallon