import { all, call, takeLatest, put } from 'redux-saga/effects'
import { AxiosResponse } from 'axios'

import * as services from './services'
import * as actions from './actions'
import { AnyAction } from 'redux'
import { toast } from 'react-toastify'

export function* postStudentActivitySaga({ payload }: AnyAction) {
  try {
    const formData = new FormData()
    formData.append('file', payload.file)
    const response: AxiosResponse<any> = yield call(services.postStudentActivityService, { id: payload.id, formData });
    if (response.status === 201 || response.status === 200) {
      yield put(actions.postStudentActivitySuccess())
    }
  } catch (error: any) {
    if (error?.response.data === 'overdue') {
      toast.error('Atividade já passou da data de envio.')
    } else {
      toast.error('Erro ao enviar atividade.')
    }
    yield put(actions.postStudentActivityRejected(error))
  }
}

export function* postStudentChatCommentSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.postStudentChatService, payload)
    if (response.status === 200) {
      if (payload?.isRewrite) {
        yield put(actions.postRewriteStudentChatCommentSuccess(response?.data))
      } else {
        yield put(actions.postStudentChatCommentSuccess(response?.data))
      }
    }
  } catch (error) {
    if (payload?.isRewrite) {
      yield put(actions.postRewriteStudentChatCommentRejected(error))
    } else {
      yield put(actions.postStudentChatCommentRejected(error))
    }
  }
}

export function* getStudentActivityListSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityListService, payload)
    if (response.status === 200) {
      yield put(actions.getStudentActivityListSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getStudentActivityListRejected(error))
  }
}

export function* getCalendarActivitiesListSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityListService, payload)
    if (response.status === 200) {
      yield put(actions.getCalendarActivitiesListSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getCalendarActivitiesListRejected(error))
  }
}

export function* getStudentActivityByIdSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityByIdService, payload)
    if (response.status === 200) {
      yield put(actions.getStudentActivityByIdSuccess(response?.data))
    }
  } catch (error: any) {
    if (error?.response?.status === 404) {
      toast.error('Atividade não existe.')
    } else {
      toast.error('Erro ao buscar atividade.')
    }
    yield put(actions.getStudentActivityByIdRejected(error))
  }
}

export function* getStudentActivityReWriteByIdSaga({ payload }: AnyAction) {
  try {
    const response: AxiosResponse<any> = yield call(services.getStudentActivityByIdService, payload)
    if (response.status === 200) {
      yield put(actions.getStudentActivityReWriteByIdSuccess(response?.data))
    }
  } catch (error) {
    yield put(actions.getStudentActivityReWriteByIdRejected(error))
  }
}

// Watchers
export function* watchGetStudentActivityListData() {
  yield takeLatest(actions.getStudentActivityListRequest.type, getStudentActivityListSaga)
}
export function* watchGetCalendarActivitiesListData() {
  yield takeLatest(actions.getCalendarActivitiesListRequest.type, getCalendarActivitiesListSaga)
}
export function* watchGetStudentActivityByIdData() {
  yield takeLatest(actions.getStudentActivityByIdRequest.type, getStudentActivityByIdSaga)
}
export function* watchGetStudentActivityReWriteByIdData() {
  yield takeLatest(actions.getStudentActivityReWriteByIdRequest.type, getStudentActivityReWriteByIdSaga)
}
export function* watchPostStudentActivity() {
  yield takeLatest(actions.postStudentActivityRequest.type, postStudentActivitySaga)
}
export function* watchPostStudentChatComment() {
  yield takeLatest([
    actions.postStudentChatCommentRequest.type,
    actions.postRewriteStudentChatCommentRequest.type,
  ], postStudentChatCommentSaga)
}

export default function* activitySagas() {
  yield all([
    watchGetStudentActivityListData(),
    watchGetStudentActivityByIdData(),
    watchGetStudentActivityReWriteByIdData(),
    watchPostStudentActivity(),
    watchGetCalendarActivitiesListData(),
    watchPostStudentChatComment()
  ])
}
