
import { styled } from '@mui/material/styles'

export const RoundedButton = styled('button')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.light,
  borderRadius: '56px',
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 600,
  gap: '4px',
  lineHeight: '120%',
  padding: '15px 25px',
  ':disabled': {
    color: theme.palette.text.secondary,
    background: 'rgba(224, 224, 224, 0.40)',
    cursor: 'not-allowed'
  },
  ':hover': {
    opacity: '0.7'
  }
}))

export const RoundedButtonNavigate = styled('a')(({ theme }) => ({
  alignItems: 'center',
  background: theme.palette.primary.light,
  borderRadius: '56px',
  color: '#fff',
  cursor: 'pointer',
  display: 'flex',
  fontFamily: 'Montserrat',
  fontSize: '16px',
  fontWeight: 600,
  gap: '4px',
  lineHeight: '120%',
  padding: '15px 25px',
  textDecoration: 'none',
  ':disabled': {
    color: theme.palette.text.secondary,
    background: 'rgba(224, 224, 224, 0.40)',
    cursor: 'not-allowed'
  },
  ':hover': {
    opacity: '0.7'
  }
}))

