import React from 'react'

// Assets
import reportIcon from '../../../assets/activities/report-icon.svg'

// Components
import { PageSubTitle } from '../../title/PageSubTitle'

// Redux
import { ICorrection, ICriteriaGrade } from '../../../store/activity/types'

// Styles
import styles from './styles/competenceCollpseMobile.module.scss'
import SingleCompetenceMobile from './SingleCompetenceMobile'


type ICompetenceMobileProps = {
  firstSubmissionCorrection: ICorrection
  secondSubmissionCorrection?: ICorrection
}

const CompetenceMobile: React.FC<ICompetenceMobileProps> = ({
  firstSubmissionCorrection,
  secondSubmissionCorrection
}) => {
  return (
    <div className={styles.container}>
      <PageSubTitle>
        <img src={reportIcon} />
        Relatório de correção
      </PageSubTitle>
      <h3 className={styles.sub_tittle}>Notas por critério</h3>
      <div className={styles.single_compentece_container}>
        {
          firstSubmissionCorrection?.criteria_grades.map((item: ICriteriaGrade, index: number) => {
            const secondSubmissionItems: ICriteriaGrade =
              secondSubmissionCorrection?.criteria_grades[index] || {
                criteria_id: null,
                criteria_name: '',
                criteria_max: 0,
                grade: 0,
                percentage: 0,
                comment: ''
              }
            return <SingleCompetenceMobile
              key={item?.criteria_id}
              order={index}
              firstSubmissionItem={item}
            {...(secondSubmissionCorrection?.id !== null && { secondSubmissionItem: secondSubmissionItems })}

            />
          })
        }
      </div>
    </div>
  )
}

export default CompetenceMobile