import React from 'react'
import DialogModal from './DialogModal'
import { Box, Divider, Typography } from '@mui/material'

type IRedactorAudioModalProps = {
  hasCloseButton: boolean
  open: boolean
  handleClose: () => void
  closeOnBackdropClick: boolean
  audioUrl: string
}

const RedactorAudioModal: React.FC<IRedactorAudioModalProps> = ({
  hasCloseButton,
  open,
  handleClose,
  closeOnBackdropClick,
  audioUrl
}) => {
  return (
    <DialogModal
      hasCloseButton={hasCloseButton}
      open={open}
      handleClose={handleClose}
      closeOnBackdropClick={closeOnBackdropClick}
    >
      <Box
        display='flex'
        flexDirection='column'
        paddingInline='16px'
      >
        <Typography
          component='h4'
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            marginBottom: '24px',
            textAlign: 'center',
          }}
        >
          Áudio:
        </Typography>
        <Divider />
        <Box display='flex' justifyContent='center' mt='24px'>
          <audio controls>
            <source src={audioUrl} type='audio/mpeg' />
            Your browser does not support the audio element.
          </audio>
        </Box>
      </Box>
    </DialogModal>
  )
}

export default RedactorAudioModal