import { createReducer } from '@reduxjs/toolkit'
import { StateType } from './types'
import * as actions from './actions'

const initialState: StateType = {
  items: {
    activities: [],
    pagination: {
      count: 0,
      pages_total: 0
    }
  },
  item: {
    id: null,
    name: '',
    subject: '',
    sheet_name: '',
    jury: '',
    max_grade: 0,
    start_datetime: '',
    end_datetime: '',
    rewrite_of_id: null,
    has_rewrite_id: null,
    file_pdf: '',
    file_image: '',
    group_name: '',
    answer: {
      correction: {
        id: null,
        criteria_grades: [],
        markers: []
      },
      file: '',
      grade: null,
      group_name: '',
      submission_date: '',
      status: ''
    },
    chat: []
  },
  calendarActivities: [],
  rewrite: {
    id: null,
    name: '',
    subject: '',
    sheet_name: '',
    jury: '',
    max_grade: 0,
    start_datetime: '',
    end_datetime: '',
    rewrite_of_id: null,
    has_rewrite_id: null,
    file_pdf: '',
    file_image: '',
    group_name: '',
    answer: {
      correction: {
        id: null,
        criteria_grades: [],
        markers: []
      },
      file: '',
      grade: null,
      group_name: '',
      status: '',
      submission_date: ''
    },
    chat: []
  },
  isFetchingChat: false,
  isFetching: false,
  isFetchingCalendarActivities: false,
  isError: false,
  isSaving: false,
  errorMessage: ''
}

const activityReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getStudentActivityListRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getStudentActivityListSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
        ...state,
        isFetching: false,
        isError: false,
        errorMessage: '',
        items: {
          activities: action.payload.items,
          pagination: {
            count: action.payload.count,
            pages_total: action.payload.pages_total
          }
        },
      })
    })
    .addCase(actions.getStudentActivityListRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.getCalendarActivitiesListRequest, (state: StateType): StateType => ({
      ...state,
      isFetchingCalendarActivities: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getCalendarActivitiesListSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
        ...state,
        isFetchingCalendarActivities: false,
        isError: false,
        errorMessage: '',
        calendarActivities: action.payload.items
      })
    })
    .addCase(actions.getCalendarActivitiesListRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetchingCalendarActivities: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.getStudentActivityByIdRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getStudentActivityByIdSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      item: action.payload,
    }))
    .addCase(actions.getStudentActivityByIdRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.getStudentActivityReWriteByIdRequest, (state: StateType): StateType => ({
      ...state,
      isFetching: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.getStudentActivityReWriteByIdSuccess, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: false,
      errorMessage: '',
      rewrite: action.payload,
    }))
    .addCase(actions.getStudentActivityReWriteByIdRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetching: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.postStudentActivityRequest, (state: StateType): StateType => ({
      ...state,
      isSaving: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.postStudentActivitySuccess, (state: StateType): StateType => ({
      ...state,
      isSaving: false,
      isError: false,
      errorMessage: 'Redação enviada com sucesso!',
    }))
    .addCase(actions.postStudentActivityRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isSaving: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.postStudentChatCommentRequest, (state: StateType): StateType => ({
      ...state,
      isFetchingChat: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.postStudentChatCommentSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
        ...state,
        item: {
          ...state.item,
          chat: [
            ...state.item.chat,
            action.payload
          ]
        },
        isFetchingChat: false,
        isError: false,
        errorMessage: 'Comentário enviado com sucesso!',
      })
    })
    .addCase(actions.postStudentChatCommentRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetchingChat: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.postRewriteStudentChatCommentRequest, (state: StateType): StateType => ({
      ...state,
      isFetchingChat: true,
      isError: false,
      errorMessage: ''
    }))
    .addCase(actions.postRewriteStudentChatCommentSuccess, (state: StateType, action: { payload: any }): StateType => {
      return ({
        ...state,
        rewrite: {
          ...state.rewrite,
          chat: [
            ...state.rewrite.chat,
            action.payload
          ]
        },
        isFetchingChat: false,
        isError: false,
        errorMessage: 'Comentário enviado com sucesso!',
      })
    })
    .addCase(actions.postRewriteStudentChatCommentRejected, (state: StateType, action: { payload: any }): StateType => ({
      ...state,
      isFetchingChat: false,
      isError: true,
      errorMessage: action.payload
    }))
    .addCase(actions.resetActivity, (state: StateType): StateType => ({
      ...state,
      item: {
        ...initialState.item
      },
      rewrite: {
        ...initialState.rewrite
      }
    }))
})

export default activityReducer
