import React, { useState } from 'react'

// Assets
import SendMessageIcon from '../../assets/chat/send-message-icon.svg'

// Redux
import { useDispatch } from 'react-redux'

// Styles
import styles from './styles/chatMessageInput.module.scss'
import { postRewriteStudentChatCommentRequest, postStudentChatCommentRequest } from '../../store/activity/actions'
import { getActivityId } from '../../utils/functions'

type IMessageInputProp = {
  isRewrite?: boolean
}

const MessageInput: React.FC<IMessageInputProp> = ({
  isRewrite
}) => {
  const dispatch = useDispatch()
  const path = location?.pathname
  const activityId = getActivityId(path)

  const [message, setMessage] = useState('')

  const handleSend = () => {
    if (message.trim()) {
      
      if (isRewrite) {
        setMessage('')
        dispatch(postRewriteStudentChatCommentRequest({ activity_id: activityId, comment: message, isRewrite }))
        setMessage('')
      } else {
        setMessage('')
        dispatch(postStudentChatCommentRequest({ activity_id: activityId, comment: message }))
        setMessage('')
      }
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSend()
    }
  }

  return (
    <div className={styles.message_input_container}>
      <input
        type='text'
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder='Escreva sua mensagem'
        className={styles.message_input}
        onKeyDown={handleKeyDown}
      />
      <button className={styles.send_button} onClick={handleSend}>
        <img src={SendMessageIcon} />
      </button>
    </div>
  )
}

export default MessageInput
