import React, { useState } from 'react'

// Assets
import GuidedWritingWomanWithBooks from '../../assets/guidedWriting/guided-writing-image.png'
import EssayIcon from '../../assets/guidedWriting/essay.svg'
import PlusRoundedIcon from '../../assets/guidedWriting/plus-rounded-icon.svg'
import ClipIcon from '../../assets/guidedWriting/clip-icon.svg'
import GirlWithMagnifier from '../../assets/guidedWriting/girl-with-magnifier.png'

// Components
import CustomSearchInput from '../../components/input/CustomSearchInput'
import Notification from '../../components/notification/Notification'
import LogoutButton from '../../components/button/logout/LogoutButton'
import PersonalDataDropDownMenu from '../../components/dropDown/PersonalDataDropDownMenu'
import { Box, Pagination, Typography } from '@mui/material'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import EssayThemeCards from './EssayThemeCards'
import GuidedWritingTable from '../../components/table/guidedWriting/GuidedWritingTable'
import { RoundedButtonColorLight } from '../../components/button/RoundedButtonColorLight'
import { PageMainTitle } from '../../components/title/PageMainTitle'
import { PageSubTitle } from '../../components/title/PageSubTitle'
import SelectInput from '../../components/input/SelectInput'
import NavigationTitleWithBackButton from '../../components/navigation/NavigationTitleWithBackButton'

// Style
import styles from './styles/guidedWritingDesktop.module.scss'
import { useTheme } from '@mui/material/styles'

// Utils
import { scrollToTop } from '../../utils/functions'
import { useNavigate } from 'react-router-dom'

const GuidedWritingDesktopContainer = () => {
  const theme = useTheme()
  const [searchName, setSearchName] = useState<string>('')
  const [cardsPage, setCardsPage] = useState(1)
  const [mainTheme, setMainTheme] = useState(true)
  const navigate = useNavigate()

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setSearchName(value)
  }

  const [tabValue, setTabValue] = React.useState('1')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (mainTheme) {
      setMainTheme(false)
    } else {
      setMainTheme(true)
    }
    setTabValue(newValue)
  }

  const handleButtonNavigate = () => {
    if (!mainTheme) {
      navigate('/redacao-guiada/adicionar-tema')
    } else {
      setMainTheme(false)
      setTabValue('2')
    }
  }

  const handleChangeCardsPage = (event: React.ChangeEvent<unknown>, value: number) => {
    setCardsPage(value)
    // dispatch(action({ param: dummy, page: value }))
    scrollToTop()
  }

  const options = [
    { label: 'ENEM', value: 'ENEM' },
    { label: 'Concurso', value: 'concurso' },
    { label: 'ITA', value: 'ita' }

  ]

  const bancaOptions = [
    { label: 'Selecione...', value: 'placeholder' },
    ...options
  ]
  return (
    <div className={styles.container}>
      <div
        className={styles.top_header}
      >
        <CustomSearchInput
          name='search'
          value={searchName}
          onChange={handleSearch}
          maxWidth='525px'
        />

        <div
          className={styles.notification_container}
        >
          <Box display='flex' gap='8px'>
            <Notification
              background='#F2F5FF'
            />
            <LogoutButton
              background='#F2F5FF'
            />
          </Box>
          <PersonalDataDropDownMenu
            background='#F2F5FF'
          />
        </div>
      </div>
      <NavigationTitleWithBackButton
        backTo='/'
        text={`${mainTheme ? 'Minhas redações' : 'Meus Temas'} / Adicionar`}
      />
      <div
        className={styles.title_container}
      >
        <div className={styles.title}>
          <PageMainTitle>
            <img src={mainTheme ? EssayIcon : ClipIcon} />
            {mainTheme ? 'Redação Guiada' : 'Tema'}
          </PageMainTitle>
          <p className={styles.text}>
            {
              mainTheme ?
                'Baixe temas de redação com fichas instrutivas, filtre por bancas e eixos temáticos. Escreva sua redação e envie para avaliação dos professores para aprimorar suas habilidades.'
                : 'Identifique o tema da sua redação. Caso não esteja na nossa lista, adicione-o selecionando a opção "NOVO TEMA".'}
          </p>
        </div>
        {!mainTheme && (
          <Typography
            component='p'
            sx={{
              borderRadius: '32px',
              background: '#F2ECFC',
              padding: '24px 32px',
              position: 'absolute',
              maxWidth: '293px',
              right: '290px'
            }}
          >
            Revise seus erros antes de novas redações! Apenas redações escritas à caneta e legíveis serão corrigidas
          </Typography>
        )
        }
        <img src={mainTheme ? GuidedWritingWomanWithBooks : GirlWithMagnifier} />
      </div>

      <div
        className={styles.tabs_container}
      >
        <Box sx={{ width: '100%' }}>
          <Box width='100%'>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
                <TabList
                  onChange={handleChange}
                  sx={{
                    '.Mui-selected': {
                      color: theme.palette.text.primary + '!important',
                      fontWeight: 600,
                      fontSize: '24px'
                    },
                    '.MuiTab-root': {
                      color: theme.palette.text.secondary,
                      fontWeight: 600,
                      fontSize: '24px'
                    },
                    '.MuiTabs-indicator': {
                      background: theme.palette.primary.light,
                    }
                  }}
                >
                  <Tab label='Minhas Redações' value='1' />
                  <Tab label='Meus temas' value='2' />
                </TabList>
                <RoundedButtonColorLight onClick={handleButtonNavigate}>
                  {mainTheme ?
                    <>
                      <img src={PlusRoundedIcon} />
                      Adicionar
                    </>
                    : 'Novo Tema'
                  }
                </RoundedButtonColorLight>
              </Box>
              <TabPanel value='1'>
                <Box>
                  <GuidedWritingTable />
                </Box>
              </TabPanel>
              <TabPanel value='2'>
                <Box
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    gap: '65px',
                    marginBottom: '34px'
                  }}
                >
                  <PageSubTitle>Encontre um tema</PageSubTitle>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Typography>Banca:</Typography>
                    <SelectInput
                      width='fit-content'
                      name='selectSubject'
                      border='none'
                      onChange={() => null}
                      defaultValue='placeholder'
                      options={bancaOptions}
                    />
                  </Box>
                </Box>
                <EssayThemeCards />
                <Pagination
                  count={20}
                  size='small'
                  color='primary'
                  page={cardsPage}
                  onChange={handleChangeCardsPage}
                  sx={{
                    mt: '32px',
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>

      </div>

    </div>
  )
}

export default GuidedWritingDesktopContainer